import React, { useState, useMemo } from 'react';
import MDialog from '../../../components/controls/MDialog';
import { Button, Grid } from '@mui/material';
import MSelect from '../../../components/controls/MSelect';
import MTextField from '../../../components/controls/MTextField';
import SaveIcon from '@mui/icons-material/Save';
import { Confirm, useDataProvider, useNotify } from 'react-admin';
import AgGrid from '../../../components/ag-grid/ag-grid';
import { ActionColumn } from '../../../components/ag-grid/aggrid-components';

const ProcessError = (props) => {
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const { market, errorList, setAllTypes, setErrorList } = props;

    const [newError, setNewError] = useState('');
    const [selectMarket, setSelectMarket] = useState('')
    const [popups, setPopups] = useState({ main: false, delete: false, edit: { open: false, data: null } })
    const [rowData, setOriginError] = useState(null);
    const [errorData, setErrorData] = useState({ forUpdate: errorList, forGrid: errorList });
    const [invalidInput, setInvalidInput] = useState(false);

    const handleSave = (param) => {
        if (selectMarket === '' || newError === '') {
            notify('Please select market and fill in new error !', { type: 'error' });
            setInvalidInput(true);
            return;
        }

        let upperMarket = selectMarket.charAt(0).toUpperCase() + selectMarket.slice(1);
        dataProvider.postData('ProcessTrackingReport', 'AddError', { market: upperMarket, error: newError, category: "ProcessTracking" })
            .then((res) => {
                if (res?.data?.message == 'success') {
                    errorData.forUpdate.push(`${upperMarket}: ${newError}`);
                    setErrorData({
                        forUpdate: errorData.forUpdate,
                        forGrid: errorData.forUpdate.filter(x => x?.split(":")[0].toLowerCase() == upperMarket.toLowerCase())
                    })
                    notify("Create successfully", { type: 'success' });
                } else if (res?.data?.message == 'exist') {
                    notify("Create failed => Existing Error", { type: 'error' });
                } else {
                    notify("Create failed", { type: 'error' });
                }
                sessionStorage.removeItem('RaStore.orderProcessTypes');
            }).catch(err => { notify(`Something went wrong: ${err}`, { type: 'error', autoHideDuration: 2000 }); })
            .finally(_ => setInvalidInput(false))
    }

    const defaultColDef = useMemo(() => {
        return {
            sortable: true,
            resizable: true,
            flex: 1,
            filter: false,
            suppressMovable: true//block move columns
        };
    }, []);
    var colDefs = [
        { field: 'market', headerName: 'Market', maxWidth: 100 },
        {
            field: 'error', headerName: 'Error',
            editable: true,
            minWidth: 800
        },
        {
            headerName: "Action", minWidth: 150,
            cellRenderer: ActionColumn,
            colId: "action"
        }
    ]

    const handleEdit = (market, error, type, rowData) => {
        dataProvider.postData('ProcessTrackingReport', 'EditError', { market: market, error: error, type: type, originError: rowData, category: "ProcessTracking" })
            .then((res) => {
                if (res?.data?.message == 'success') {
                    if (type == 'edit') {
                        const updateCondition = (x) => x.split(":")[0]?.toLowerCase() == market.toLowerCase() && x.slice(x.split(":")[0].length + 1).trim() == rowData;
                        const _forUpdate = errorData.forUpdate.map(x => updateCondition(x) ? market + ": " + error : x);
                        const _forGrid = errorData.forGrid.map(x => updateCondition(x) ? market + ": " + error : x);
                        setErrorData({ forUpdate: _forUpdate, forGrid: _forGrid.sort() });
                    } else if (type == 'delete') {
                        let newlist = errorData.forUpdate.filter(x => x.toLowerCase() != (market + ': ' + error).toLowerCase());
                        setErrorData({ forUpdate: newlist, forGrid: newlist.sort() });
                    }
                    notify(`${type == 'edit' ? 'Update' : 'Delete'} successfully`, { type: 'success' });
                } else {
                    notify(`${type == 'edit' ? 'Update' : 'Delete'} failed`, { type: 'error' });
                }
                sessionStorage.removeItem('RaStore.orderProcessTypes');
            }).catch(err => { notify(`Something went wrong: ${err}`, { type: 'error', autoHideDuration: 2000 }); }).finally(_ => {
                setPopups({ ...popups, delete: false, edit: { open: false } });
                setInvalidInput(false);
            });
    }

    const onCellClicked = ((params) => {

        if (params.column.colId === "action" && params.event.target.dataset.action) {
            let action = params.event.target.dataset.action;

            if (action === "edit") {
                params.api.startEditingCell({
                    rowIndex: params.node.rowIndex,
                    // gets the first columnKey, due to full row edit => open 1 cell for edit will open all editable cells in row
                    colKey: params.columnApi.getDisplayedCenterColumns()[0].colId
                });
            }

            if (action === "delete") {
                setPopups({ ...popups, delete: true });
                setOriginError(params.data);
            }

            if (action === "update") {
                params.api.stopEditing(false);
                //handleEdit(params.data.market, params.data.error, 'edit');
                params.api.stopEditing();
            }

            if (action === "cancel") {
                params.api.stopEditing(true);
            }
        }
    });
    const onCellEditingStopped = (params) => {
        params.api.refreshCells({
            columns: ["action"],
            rowNodes: [params.node],
            force: true
        });
        if (params.valueChanged) {
            setPopups({ ...popups, edit: { open: true, params: params } });
        }
    }
    //for changing layout
    const onCellEditingStarted = (params) => {
        params.api.refreshCells({
            columns: ["action"],
            rowNodes: [params.node],
            force: true
        });
    };

    return (
        <>
            <Button onClick={() => setPopups({ ...popups, main: true })}>+ Add Error</Button>
            <MDialog
                maxWidth={'lg'}
                open={popups.main}
                handleClose={() => {
                    setAllTypes(prev => ({ ...prev, errors: errorData.forUpdate }));
                    setErrorList(errorData.forUpdate.sort());
                    setPopups({ ...popups, main: false });
                }}
                title={'Add New Process Error'}
                name={'process-error-dialog'}
                children={
                    <Grid container style={{ height: '55vh' }}>
                        <Grid item xs={2}>
                            <MSelect error={invalidInput} label={'Market'} choices={market || []} size={"small"} required onChange={(value) => {
                                let newData = value ? errorData.forUpdate.filter(x => x.split(':')[0]?.trim().toLowerCase() == value.toLowerCase()) : errorData.forUpdate;
                                setErrorData(prev => ({ ...prev, forGrid: newData.sort() }));
                                setSelectMarket(value);
                            }} />
                        </Grid>
                        <Grid item xs={8}>
                            <MTextField error={invalidInput} label={'Error'} placeholder={"Input new error"} onChange={(value) => setNewError(value)} required />
                        </Grid>
                        <Grid item xs={1}>
                            <Button style={{ marginTop: '20px', marginLeft: '10px' }} variant='contained' color='info' startIcon={<SaveIcon />} onClick={handleSave}>Save</Button>
                        </Grid>
                        <Grid item xs={12} sx={{ '.ag-cell': { 'line-height': '40px' } }}>
                            <AgGrid //https://www.ag-grid.com/javascript-data-grid/grid-options                            
                                columnDefs={colDefs}
                                defaultColDef={defaultColDef}
                                setDefaultValues={{ sortField: { field: 'id', order: 'asc' } }}
                                rowHeight={45}
                                rowSelection='single'
                                editType={'fullRow'}
                                onCellClicked={onCellClicked}
                                onCellEditingStopped={onCellEditingStopped}
                                onCellEditingStarted={onCellEditingStarted}
                                gridHeight={'45vh'}
                                hidePaging={true}
                                rowData={errorList?.length > 0 ? errorData.forGrid.map((value, index) => {
                                    let arr = value.split(":");
                                    return { market: arr[0].trim(), error: value.slice(arr[0].length + 1).trim(), id: index };
                                }) : []}
                            ></AgGrid>
                        </Grid>
                    </Grid>
                }
            >
            </MDialog>
            <Confirm isOpen={popups.delete} title={`Delete Error?`} content={`Market: [${rowData?.market}].\nError: "${rowData?.error}"`}
                onConfirm={() => handleEdit(rowData?.market, rowData?.error, 'delete')} onClose={() => setPopups({ ...popups, delete: false })} />
            <Confirm isOpen={popups.edit.open} title={`Edit Error?`} content={`Do you want to update [${popups.edit.params?.oldValue}] => [${popups.edit.params?.newValue}]?`}
                onConfirm={() => handleEdit(popups.edit.params.data.market, popups.edit.params.data.error, 'edit', popups.edit.params.oldValue)}
                onClose={() => {
                    popups.edit.params.api.getRowNode(popups.edit.params.data.id).setDataValue('error', popups.edit.params.oldValue);
                    setPopups({ ...popups, edit: { open: false } });
                }} />
        </>
    );
}
export default ProcessError