import React, { useRef, useState } from 'react';
import MDialog from '../../../components/controls/MDialog';
import { Grid, Stack, LinearProgress, Button, FormControl, Checkbox, TextField, Divider, FormControlLabel } from '@mui/material';
import { Confirm, useNotify } from 'react-admin';
import AgGrid from '../../../components/ag-grid/ag-grid';
import { buildQueryString, dataProvider } from '../../../DataProvider';
import MSelect from '../../../components/controls/MSelect';

const ApplyVenuesDialog = (props) => {
    const { applyVenues, setApplyMultipleVenues, marketType, refInputAggrid } = props;
    const [originfilterList, setOriginfilterList] = useState(applyVenues.filterList);
    const notify = useNotify();
    const refInput = useRef(null);
    //set type for filter
    const [filterList, setFilterList] = useState({...applyVenues.filterList, type_eq: applyVenues.row.type.trim()});
    //
    const [isLoading, setIsLoading] = React.useState(false);
    const [data, setData] = useState([]);
    const [gridData, setGridData] = useState([])
    const [range, setRange] = useState({ firstRow: applyVenues.row.firstrow, lastRow: applyVenues.row.lastrow, section: applyVenues.row.section });
    const [openConfirm, setOpenConfirm] = useState(false);

    React.useEffect(() => {
        handleFilterVenue();
    }, []);

    const handleFilterVenue = () => {
        if (!filterList.nga_event_venue_contains) {
            notify(`FILTER: Please insert venue ...`, { type: 'error', autoHideDuration: 2000 });
            return;
        }
        setIsLoading(true);
        dataProvider.fetchData('NgaVenueConfig', `Filter${convertFilter(filterList)}`)
            .then((result) => {
                if (result.data) {
                    let gridDataTemp = convertData(result.data?.items);
                    let data = result.data?.items;
                    let numMatchEvents = data.filter(x => x.section == applyVenues.row.section && x.firstrow == applyVenues.row.firstrow && x.lastrow == applyVenues.row.lastrow).length
                    notify(`Found ${gridDataTemp.length} venue(s) with ${numMatchEvents} events matching [section, first row, last row].`, { type: 'success', autoHideDuration: 4000 });

                    setData(data);
                    setGridData(gridDataTemp);
                } else {
                    notify(`Fail to fetch data ...`, { type: 'error', autoHideDuration: 2000 });
                    setApplyMultipleVenues({ open: false, filterList: null });
                }
            })
            .catch(err => {
                notify(`Fail to fetch data: ${err}`, { type: 'error', autoHideDuration: 2000 });
                setApplyMultipleVenues({ open: false, filterList: null });
            })
            .finally(_ => setIsLoading(false));
    }

    const convertFilter = (filter) => {
        let _filter = buildQueryString({ filter: filter }, { top: 1000000, skip: 0, orderBy: 'id asc' });
        return _filter;
    }
    //convert data for disply it on grid, remove venues which do not have selected section
    const convertData = (data) => {
        let displayData = [];
        data?.forEach(element => {
            if (displayData.find(x => x.venue === element.nga_event_venue) == null) {
                let eventsWithVenue = data.filter(x => x.nga_event_venue == element.nga_event_venue && x.section == applyVenues.row.section
                    && x.firstrow == applyVenues.row.firstrow && x.lastrow == applyVenues.row.lastrow)
                    .map(x => ({id: x.id, section: x.section, firstrow: x.firstrow, lastrow: x.lastrow }));

                if (eventsWithVenue.length > 0) {//filter section & row range same as rowdata                    
                    displayData.push({
                        type: element.type,
                        venue: element.nga_event_venue,
                        venueId: element.venue_id,
                        venueDisplay: element.nga_event_venue + `  (${eventsWithVenue.length} events)`,
                        count: eventsWithVenue.length,
                        // applyAllMarket: false
                    });
                }

            }
        });
        displayData = displayData.filter(x => x.venueId);//remove venue does not have venue id because update row range for venue must have venue-id

        return displayData;
    }

    const handleApply = () => {
        let selectedRows = refInput.current.getAgGridInstance().api.getSelectedRows();
        if (selectedRows?.length > 0 && range.firstRow != "" && range.lastRow != "") {
            setOpenConfirm(true);
        } else {
            notify(`Please select venue(s) for applying`, { type: 'error', autoHideDuration: 2000 });
        }
    }

    const handleConfirm = () => {
        setOpenConfirm(false);
        setIsLoading(true)

        let selectedRows = refInput.current.getAgGridInstance().api.getSelectedRows();
        let dataPut = data
            .filter(ev => selectedRows.some(row => ev.nga_event_venue == row.venue) && ev.section == range.section)
            .reduce((final, item) => {//get sample one event for each specific venue name
                if (final.find(x => x.nga_event_venue == item.nga_event_venue) == null) {
                    final.push(item);
                }
                return final;
            }, [])
            .map(event => {
                let override = {
                    type: event.type,
                    venue_id: event.venue_id,
                    section: range.section,
                    firstrow: range.firstRow,
                    lastrow: range.lastRow,
                    tmid: event.tmid,
                    by: event.by,
                    apply_to_venue: true,
                    // reverse_range: reverseRange
                }
                event.specVenueConfigOverride = override;
                return event;
            })

        dataProvider.postData('NgaVenueConfig', 'UpdateRowRangeMultipleVenues', dataPut, 'PUT')
            .then((response) => {
                if (response.data) {
                    notify('Update Success ...', { type: 'success', autoHideDuration: 2000 });                   
                } else {
                    notify(`Update Failed: ${response?.message}`, { type: 'error', autoHideDuration: 2000 });
                }
            })
            .catch(err => {
                notify(`Update Failed: ${err}`, { type: 'error', autoHideDuration: 2000 });
            })
            .finally(_ => {                
                refInputAggrid.current.customFilterSearch(originfilterList);//reload with default filter
                setIsLoading(false);
                setApplyMultipleVenues({ open: false, filterList: null })
            });
    }

    var colDefs = [
        {
            field: 'type',
            headerName: 'Type',
            headerCheckboxSelection: true,
            checkboxSelection: true,
            maxWidth: 150,
        },
        {
            field: 'venueDisplay',
            tooltipField: 'venue',
            headerName: 'Venue',
            minWidth: 720
        }
    ]

    return (
        <>
            <MDialog
                title={`Apply Same Venue. (Section: ${applyVenues.row.section}, First row: ${applyVenues.row.firstrow}, Last row: ${applyVenues.row.lastrow})`}
                maxWidth={'md'}
                open={applyVenues.open}
                handleClose={() => setApplyMultipleVenues({ open: false, filterList: null })}
                name={'apply-multiple-venues-dialog'}
                action={
                    <Button variant='contained' color='primary' disabled={isLoading || gridData.length == 0} onClick={handleApply}>Apply</Button>
                }
                children={
                    <>
                        {isLoading == true && <LinearProgress color='secondary' />}
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <MSelect choices={marketType} label={'Type'} defaultValue={filterList.type_eq}
                                    onChange={(ev) => setFilterList(prev => ({ ...prev, type_eq: ev ? ev : undefined }))}
                                />
                            </Grid>
                            <Grid item xs={7}>
                                <TextField defaultValue={filterList.nga_event_venue_contains} onChange={(ev) => setFilterList(prev => ({ ...prev, nga_event_venue_contains: ev.target.value }))} placeholder='Venue' />
                            </Grid>
                            <Grid item xs={2}>
                                <Button variant='contained' color='primary' onClick={handleFilterVenue} sx={{ marginTop: '20px' }}>Filter</Button>
                            </Grid>
                            <Grid item xs={12} sx={{
                                '.ag-cell': {'line-height': '40px'}                            
                            }}>                        
                                <AgGrid id={'aggrid-rowrange-applyvenue'}
                                    ref={refInput}
                                    gridHeight={'40vh'}
                                    columnDefs={colDefs}
                                    hidePaging={true}
                                    rowData={gridData}
                                    rowSelection={'multiple'}
                                    suppressRowClickSelection={true}
                                // onSelectionChanged={onSelectionChanged}
                                >
                                </AgGrid>
                            </Grid>
                            <Grid item xs={12}>
                                <p > Select section and row range:</p>
                                <Stack direction="row">
                                    <FormControl key={1} variant="standard">
                                        <Stack direction="row" sx={{ '& > :not(style)': { marginLeft: 1 } }}>
                                            <TextField
                                                required
                                                id="outlined-required1"
                                                label="Section"
                                                value={range.section}
                                                variant='outlined'
                                                disabled
                                            // onChange={(event) => setRange(prev => ({ ...prev, section: range.section }))}                                         
                                            />
                                            <Divider orientation="vertical" variant="middle" flexItem />
                                            <TextField
                                                required
                                                id="outlined-required2"
                                                label="First Row"
                                                value={range.firstRow}
                                                variant='outlined'
                                                onChange={(event) => setRange(prev => ({ ...prev, firstRow: event.target.value }))}
                                            />
                                            <Divider orientation="vertical" variant="middle" flexItem />
                                            <TextField
                                                required
                                                id="outlined-required3"
                                                label="Last Row"
                                                value={range.lastRow}
                                                variant='outlined'
                                                onChange={(event) => setRange(prev => ({ ...prev, lastRow: event.target.value }))}
                                            />
                                            <FormControlLabel control={<Checkbox defaultChecked={false} onChange={(event) => setRange(prev => ({ ...prev, firstRow: range.lastRow, lastRow: range.firstRow }))} />}
                                                label="Reverse Range" sx={{ marginLeft: 1 }} />
                                        </Stack>
                                        <p style={{ color: 'orange' }}> (This apply will not override configure of row range for specific events before (if have))</p>
                                    </FormControl>


                                </Stack>
                            </Grid>
                        </Grid>
                    </>
                }
            >
            </MDialog>
            <Confirm isOpen={openConfirm} title='Update Row Range'
                content={
                    <p>Are you sure to update
                        <span style={{ color: 'orange' }}> [{applyVenues.row.section}]</span> with row range from
                        <span style={{ color: 'orange' }}> [{applyVenues.row.firstrow} - {applyVenues.row.lastrow}]</span> to
                        <span style={{ color: 'orange' }}> [{range.firstRow} - {range.lastRow}]</span>
                    </p>
                }
                onConfirm={handleConfirm} onClose={() => setOpenConfirm(false)}
            />
        </>
    );
}
export default ApplyVenuesDialog