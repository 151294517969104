import React, { useState } from 'react';
import { Confirm } from 'react-admin';
import { REASON_TAGS } from '../formatters/constants';
import MAutoComplete from '../../../components/controls/MAutoComplete';

const FIELD_REQUIRED = 'This field is required';
const AutocompleteConfirm = ({ isOpen, title, showReason = true, onConfirm, onClose }) => {
    const [reason, setReason] = useState('');
    const [isError, setError] = useState(false);
    let reasonTags = REASON_TAGS;
    reasonTags = reasonTags.map(x => ({ ...x, name: x.id + ': ' + x.name }));

    React.useEffect(() => {
        setReason('');
    }, [isOpen]);

    return (
        <Confirm sx={{
            '.MuiDialog-paper': {
                minWidth: 600
            },
        }} isOpen={isOpen}
            title={title}
            content={
                showReason &&
                <MAutoComplete
                    label={'Reason'}
                    choices={reasonTags}
                    onChange={(selected) => {
                        setReason(selected.map(x => x.id).join(','));
                        if (selected) setError(false);
                        else setError(true);
                    }}
                    error={isError}
                    helperText={isError ? FIELD_REQUIRED : null}
                />
            }
            onClose={onClose}
            onConfirm={() => {
                if (showReason && !reason) {
                    setError(true);
                    return;
                }
                onConfirm(reason);
            }} />
    );
};

export default AutocompleteConfirm;
