import React, { useMemo, useEffect, useState, useImperativeHandle, forwardRef, memo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import AgGridPaging from './aggrid-paging';
import { LinearProgress, useColorScheme, Backdrop, CircularProgress } from '@mui/material';
import style from './style.css';
import PropTypes from 'prop-types';

const defaultProps = {
    defaultValues: {
        rowData: [],
        rowCount: null,
        isSearch: false, //default = false
        isSecondLoad: false, // prevent multiple request
        isLoading: false, // on/off LinearProgress
        sortField: { field: 'id', order: 'asc' }, // default sort field       
        filter: {},
    },
};
const AgGrid = forwardRef((props, ref) => {
    const { defaultValues = defaultProps.defaultValues, onCellClicked,
        children, list, setDefaultValues, columnDefs, rowData, gridHeight, hidePaging, gridOptions
    } = props;
    const gridStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
    const containerStyle = useMemo(() => ({ width: '100%', height: gridHeight || '83vh' }), []);
    const agGridRef = React.useRef();

    document.getElementById(props.id)?.addEventListener('contextmenu', event => { event.preventDefault() });
    const [dataApi, setDataApi] = useState({
        rowData: [],
        rowCount: null,
        isSearch: false, //default = false
        isSecondLoad: false, // prevent multiple request
        isLoading: false, // on/off LinearProgress
        sortField: { field: 'id', order: 'asc' }, // default sort field       
        filter: {},
    });
    if (columnDefs) {
        columnDefs.forEach(col => {
            do {
                var temp = col;
                if (!temp.field && temp.children) {
                    temp = col.children;
                }
            }
            while ((!temp.field && temp.children))
            if (Array.isArray(temp)) {
                temp.forEach(tempCol => tempCol['comparator'] = (valueA, valueB, nodeA, nodeB) => { return null })
            } else {
                temp['comparator'] = (valueA, valueB, nodeA, nodeB) => { return null };
            }
        });
    }
    useEffect(() => {
        if (defaultValues) {
            setDataApi((prevData) => {
                let newData = { ...prevData };
                for (let key in defaultValues) {
                    newData[key] = defaultValues[key];
                }
                return newData;
            })
        }
    }, [])
    const customFilterSearch = (filter) => {
        setDataApi({ ...dataApi, isSearch: true, filter: filter });
    }
    const showLoading = (isShow) => {
        setDataApi({ ...dataApi, isLoading: isShow });
    }
    const onSortChanged = (params) => {
        var sortField = params.columnApi.getColumnState().filter(c => c.sort).map(c => ({ field: c.colId, order: c.sort.toUpperCase() }))[0];
        if (typeof sortField !== 'undefined') {
            let _isLoading = false;
            if (dataApi.sortField.field != sortField.field || (dataApi.sortField.field == sortField.field && dataApi.sortField.order.toUpperCase() != sortField.order)) {
                _isLoading = true;
            }
            setDataApi({ ...dataApi, sortField: sortField, isLoading: agGridRef.current.props.rowData?.length > 0 && _isLoading ? true : false });
            if (setDefaultValues) {
                setDefaultValues({ ...defaultValues, sortField: sortField });
            }
        }
        else {
            setDataApi({ ...dataApi, sortField: { field: dataApi.sortField.field, order: 'asc' }, isLoading: agGridRef.current.props.rowData?.length > 0 ? true : false });
        }
    }

    useImperativeHandle(ref, () => ({
        customFilterSearch: customFilterSearch,
        showLoading: showLoading,
        getAgGridInstance() { return agGridRef.current; }
    }));

    //#region For copy text in cell
    const onCellKeyDown = (params) => {
        if (params.event.ctrlKey && params.event.keyCode == 67) {
            navigator.clipboard.writeText(params.data[params.colDef.field]);
        }
    }
    const finalOptions = {
        ...gridOptions,
        suppressCellSelection: true,  // Prevents AG Grid from selecting the whole cell
        suppressClipboardPaste: true, // Prevents AG Grid from overriding clipboard paste
    }
    //#endregion

    //#region handle oncellClick
    //prevent display multiple edit-popup
    const handleCellClicked = (params) => {
        if (!onCellClicked) {
            //close all existing popup
            const gridApi = params.api;
            const editedCell = gridApi.getFocusedCell();
            gridApi.stopEditing()

            //re open new clicked editable cell
            setTimeout(() => {
                const { rowIndex, column } = editedCell;
                // Re-trigger the default cell click behavior
                gridApi.startEditingCell({
                    rowIndex,
                    colKey: column.colId,
                });
            }, 100);
        } else {
            onCellClicked(params);
        }
    };
    //#endregion

    const { mode } = useColorScheme();
    const agTheme = "ag-theme-alpine" + (mode == "dark" ? "-dark" : "");
    return (
        <div style={containerStyle}>
            {/* {dataApi.isLoading == true && <LinearProgress color='secondary' />} */}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={dataApi.isLoading}>
                <CircularProgress color='inherit' />
            </Backdrop>
            <div className={agTheme} style={gridStyle} id={props.id}>
                <AgGridReact //https://www.ag-grid.com/javascript-data-grid/grid-options
                    {...props}
                    columnDefs={columnDefs}
                    ref={agGridRef}
                    rowData={rowData || dataApi.rowData}
                    onSortChanged={onSortChanged}
                    loadingCellRenderer={<LinearProgress />}
                    onCellKeyDown={onCellKeyDown}
                    gridOptions={finalOptions}
                    onCellClicked={handleCellClicked}
                >
                    {children}
                </AgGridReact>
            </div>
            {!hidePaging && <AgGridPaging
                list={list}
                dataApi={dataApi}
                setDataApi={setDataApi}
            />}
        </div>
    )
})
AgGrid.propTypes = {
    /** useRef for call built-in function in AgGrid */
    ref: PropTypes.any,
    /** resource name */
    list: PropTypes.string,
    /** override default setting */
    defaultValues: PropTypes.object,
    /** override sort order for export */
    setDefaultValues: PropTypes.func
}

export default memo(AgGrid)