import React, { useState, useRef } from "react";
import { dayjs, formatLocalTime, toLocalTime } from "../../components/ex_dayjs";
import { Datagrid, FunctionField, TextField, useDataProvider } from "react-admin";
import RaList from "../../components/ra-list/RaList";
import RenderControls from "../../components/ra-list/RenderControls";
import { convertDataForMSelect, formatEnumToName, getRaStore, setDefaultValuesFromURL, setRaStore } from '../../utils/common';
import ProblemError from "./component/problem-error";
import DetailsView from "./component/details-view";
import ActionField from "./component/action-field";
import { actionType } from "./common";

const ProblemOrderTrackingReport = () => {
    const dataProvider = useDataProvider();
    const refbtnFilter = useRef(null);
    const [defaultSort, setDefaultSort] = useState(['utc_Posted_On desc']);
    const [isLoadingCategory, setIsLoadingCategory] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [forRender, setForRender] = useState(null);//trigger re-render row, row must be FunctionField (it has render feature)
    const [allTypes, setAllTypes] = useState(null);
    const [errorList, setErrorList] = useState([]);
    const [detailPopup, setDetailPopup] = useState({ open: false });
    const [defaultValueFilter, setDefaultValuesFilter] = useState({
        utc_Posted_On: [dayjs().startOf('M'), dayjs()]
    });

    const handleMarketChange = (param) => {
        if (param.value == '') {
            setErrorList(allTypes.errors);
        } else {
            setErrorList(allTypes.errors.filter(x => x.split(':')[0]?.toLowerCase().trim() == param.value));
        }
    }

    const controls = [
        { component: 'select', name: 'type_eq', label: 'Market', choices: allTypes?.market || [], onChange: (handleMarketChange) },
        { component: 'daterange', name: 'utc_Posted_On', label: 'Date', xs: 2, isUTC: true },
        { component: 'text', name: 'event', label: 'Event', xs: 2 },
        { component: 'text', name: 'venue', label: 'Venue', xs: 2 },
        { component: 'number', name: 'Order_Number_eq', label: 'Order Number', xs: 1.5 },
        { component: 'autocomplete', name: 'Error_eq', label: 'Issue', xs: 1.5, choices: errorList },
    ]

    const handleRowClick = (id, resource, record) => {
        setDetailPopup(prev => ({ ...prev, open: true, data: record }));
    }

    React.useEffect(() => {
        dataProvider.fetchData('ProblemOrderTrackingReport', 'IsAdmin').then(resonse => {
            if (resonse.data) {
                setIsAdmin(true);
            }
        }).catch(_ => _);

        let _problemOrderTypes = getRaStore('problemOrderTypes');
        if (_problemOrderTypes == null) {
            setIsLoadingCategory(true);
            dataProvider.fetchData('ProblemOrderTrackingReport', 'GetAllTypes').then((response) => {
                if (response.status !== 200 && response.data) {
                    let errors = Object.keys(response.data.errors).sort();
                    let market = convertDataForMSelect(response.data.market, false);
                    setErrorList(errors);
                    setAllTypes({ market, errors });
                    setRaStore('problemOrderTypes', { market, errors })

                    setIsLoadingCategory(false);
                }
            }).catch((error) => console.log(error));
        } else {
            setAllTypes(_problemOrderTypes);
            setErrorList(_problemOrderTypes.errors);
        }

        if (location.search) {
            var newDefaultValue = { ...defaultValueFilter };
            setDefaultValuesFromURL(location.search, newDefaultValue, controls);
            setDefaultValuesFilter(newDefaultValue);
        }
    }, [])

    React.useEffect(() => {
        if (defaultValueFilter && allTypes) {
            if (Object.keys(defaultValueFilter).find(x => x == 'type_eq')) {
                setErrorList(allTypes.errors.filter(x => x.split(':')[0]?.toLowerCase().trim() == defaultValueFilter['type_eq']));
            }
            // setTimeout(() => refbtnFilter.current.click(), 500);
        }
    }, [defaultValueFilter, allTypes]);

    return (
        <>
            <RaList
                sortby={defaultSort}
                perPage={25}
                title={'Problem Order Tracking Report'}
                filters={
                    <RenderControls
                        controls={controls}
                        refFilter={refbtnFilter}
                        defaultValues={defaultValueFilter}
                        useHashUrl
                        actions={allTypes?.errors.length > 0 && <ProblemError errorList={allTypes?.errors} market={allTypes.market} setErrorList={setErrorList} setAllTypes={setAllTypes} />}
                        exportFile={{
                            filename: `ProblemOrderTrackingReport-${formatLocalTime(new Date(), 'YY-MM-DD')}`,
                            columns: ['Type', 'Order_Number', 'Event', 'Venue', 'Error', 'Author', 'Utc_Posted_On', 'Action', 'Modified_By', 'Modified'],
                            limit: 1000000
                        }}
                    />
                }
            >
                {isLoadingCategory && <p>Loading category ...</p>}
                <Datagrid
                    bulkActionButtons={false}
                    rowClick={(handleRowClick)}
                >
                    <TextField source="type" label={'Market'} />
                    <TextField source="order_Number" label={'Order Num'} />
                    <TextField source="event" label={'Event'} />
                    <TextField source="venue" label={'Venue'} />
                    <TextField source="error" label={'Issue'} />
                    <TextField source="author" label={'Author'} />
                    <FunctionField label='Date' source='utc_Posted_On' render={record => (
                        <><b>{toLocalTime(record.utc_Posted_On).format('MM/DD/YYYY')}</b>
                            <br />{toLocalTime(record.utc_Posted_On).format('hh:mm A')}</>)}
                    />
                    {isAdmin && <ActionField label='Action' isAdmin={isAdmin} setForRender={setForRender} />
                        || <FunctionField source="action" label={'Action'} render={record => actionType.find(x => x.value == record.action)?.name} />}
                    <TextField source="modified_By" label={'Modified By'} />
                    <FunctionField label='Modified' source='modified' render={(record) => record.modified && (
                        <><b>{toLocalTime(record.modified).format('MM/DD/YYYY')}</b>
                            <br />{toLocalTime(record.modified).format('hh:mm A')}
                        </>
                    )}
                    />
                </Datagrid>
                {/* {detailPopup.data && <DetailsView detailPopup={detailPopup} setDetailPopup={setDetailPopup} />} */}
            </RaList >
        </>
    );

}

const ProblemOrderTrackingReportPage = {
    name: 'ProblemOrderTrackingReport',
    list: ProblemOrderTrackingReport
}
export default ProblemOrderTrackingReportPage;