import * as React from 'react';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { MenuItem } from '@mui/material';

const defaultProps = {
    choices: [],
    variant: 'standard',
    isMulti: true
};

const MAutoComplete = ({
    choices = defaultProps.choices,
    variant = defaultProps.variant,
    isMulti = defaultProps.isMulti,
    error, helperText, label, defaultValue, value, onChange, getStyles, refInput, ...props
}) => {
    const { valueField, displayField } = props;
    const [mvalue, setMValue] = React.useState(value || []);
    const [mDefaultValue, setMDefaultValue] = React.useState(defaultValue || []);
    const onTagsChange = (event, values) => {
        setMValue(values);
        onChange(values);
    };

    React.useEffect(() => {
        if (defaultValue?.length) {
            setMDefaultValue(defaultValue);
            setMValue(defaultValue);
        }
    }, [defaultValue]);

    React.useEffect(() => {
        if (value?.length) setMValue(value);
    }, [value]);

    return (
        <Autocomplete
            {...props}
            style={{ width: '100%' }}
            freeSolo
            openOnFocus
            autoHighlight
            multiple={isMulti}
            disableCloseOnSelect={isMulti}
            options={choices}
            value={mvalue}
            defaultValue={mDefaultValue}
            onChange={onTagsChange}
            getOptionLabel={(option) => typeof option === "string" ? option : option[displayField] || option.name || option.value || ""}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip
                        style={getStyles ? { backgroundColor: getStyles(option.toString().toLowerCase()) } : {}}
                        key={option[valueField] || option.id || option}
                        label={option[displayField] || option.name || option}
                        size="small"
                        {...getTagProps({ index })}
                    />
                ))
            }
            isOptionEqualToValue={(option, value) => typeof option === "string"
                ? option == value
                : (option[displayField] || option.name || option.value || "") == (value[displayField] || value.name || value.value || "")}
            renderInput={(params) => (
                <TextField
                    {...params}
                    sx={{
                        '& .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
                            minWidth: 10
                        }
                    }}
                    ref={refInput}
                    variant={variant}
                    error={error}
                    label={label}
                    helperText={helperText}
                />
            )}
            renderOption={(props, option, state) => (
                <MenuItem {...props}
                    selected={state.inputValue == props.key}
                    divider={true}
                    value={option[valueField] || option.id || option.value || option}
                >
                    {option[displayField] || option.name || option.label || option}
                </MenuItem>
            )}
        />
    );
};

export default React.memo(MAutoComplete);