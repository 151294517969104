import * as React from "react";
import { Tooltip } from '@mui/material';
import { useRecordContext } from 'react-admin';
import { REASON_TAGS } from "./constants";

export const ReasonTagsFormat = (props) => {
  const record = useRecordContext(props);
  let reasonTags = record?.reasonTags?.split(',') || [];
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
      {reasonTags.map((value, index) => {
        let name = REASON_TAGS.find(x => x.id == value)?.name;
        return (
          <Tooltip key={index} title={name}>
            <span style={{ cursor: "pointer" }}>
              {value}
            </span>
          </Tooltip>
        )
      })}
    </div>
  );
}

ReasonTagsFormat.defaultProps = { label: 'Reason Tags' };