import React, { useState } from 'react';
import { useRecordContext, useDataProvider, useNotify } from 'react-admin';
import { actionType } from '../common';
import { LinearProgress, MenuItem, FormControl, Select } from '@mui/material';

const ActionField = (props) => {
    const { isAdmin, setForRender } = props;
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [isLoading, setIsLoading] = useState(false);    

    var record = useRecordContext();

    if (!record) return null;

    const handleChange = (props) => {
        let changedValue = props.target.value;
        if(changedValue == "None" && !record.action) return;
               
        updateAction(changedValue)
    }
    const updateAction = (value) => {
        let orderProblem = record;
        orderProblem.action = value == 'None' ? null : value;
        setIsLoading(true);

        dataProvider.postData('ProblemOrderTrackingReport', 'UpdateActionType', orderProblem)
            .then(rs => {                
                if (rs.data) {
                    record.action = rs.data.action;
                    record.modified = rs.data.modified;
                    record.modified_By = rs.data.modified_By;                   
                    setForRender(record);
                    notify('Update successfully', { type: 'success', autoHideDuration: 2000 });
                }
                else if (rs.status == 400) {
                    notify(`Update failed(Bad request): ${rs.body}`, { type: 'error', autoHideDuration: 3000 });
                }
            })
            .catch(error => {               
                console.log('Update failed:', error);
                notify(`Update failed: Status ${error.status}, Error ${error}`, { type: 'error', autoHideDuration: 3000 });
            })
            .finally(_ => {
                setIsLoading(false);                
            });            
    }

    return (
        <FormControl sx={{ m: 1, minWidth: 120, padding: 0 }} size="small" variant='standard'>
            {isLoading && <LinearProgress color="secondary" />}
            <Select
                labelId="demo-select-small"
                id="demo-select-small"
                value={record.action}
                defaultValue={record.action}
                disableUnderline={true}
                onChange={handleChange}
                sx={{ padding: 0 }}
                disabled={!isAdmin}
            >
                {actionType.filter(err => err.value != "All").map((m, index) => {
                    return <MenuItem key={index} id={m.id} value={m.value} name={m.name}>{m.name}</MenuItem>
                })}
            </Select>                    
        </FormControl>
    )
}
export default React.memo(ActionField);