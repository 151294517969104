'use strict';

import React, { useMemo, useEffect, useState, useRef } from 'react';
import { Title } from 'react-admin';
import { dataProvider } from '../../DataProvider';
import { dayjs, formatDateTime, formatLocalTime, toLocalTime } from '../../components/ex_dayjs'
import RenderControls from '../../components/ra-list/RenderControls';
import NgaVenueConfigEditor from './component/nga-venue-config-edit';
import AgGrid from '../../components/ag-grid/ag-grid';
import { loadSourceType, loadExchanges, loadAccountMaps } from '../../utils/common';
import ApplyVenuesDialog from './component/apply-venue-dialog';
import { FilterListProvider } from '../../context/FilterListProvider';

const NgaVenueConfigList = () => {
    const refInput = useRef();
    const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
    const excludedCities = ['Nevada', 'British Columbia', 'Quebec', 'Ontario'];
    const [marketType, setMarketType] = useState([]);
    const [exchanges, setExchanges] = useState([]);
    const [accountMaps, setAccountMaps] = useState([])
    const [isLoadingCategory, setIsLoadingCategory] = useState(false);
    const [defaultValues, setDefaultValues] = useState({
        sortField: { field: 'inserted', order: 'DESC' }, // default sort field       
    });
    const [defaultFilter, setDefaultFilter] = useState({
        exclude_cities_ne: excludedCities,
        exclude_passed_eq: true
    });
    const [applyVenues, setApplyMultipleVenues] = useState({ open: false, filterList: null });

    useEffect(() => {
        setIsLoadingCategory(true);
        loadSourceType(dataProvider, (response) => {
            setMarketType(response);
        });
        loadExchanges(dataProvider, (response) => {
            setExchanges(response.filter(x => x.id != 2)); //remove ticketnetwork
        });
        loadAccountMaps(dataProvider, (response) => {
            //only keep vivid_spec1 and gc_spec1
            let convertedData = response.filter(x => [2, 242].includes(x.account_id)).map((item) => ({ id: item.account_id, name: item.listing_account }));
            setAccountMaps(convertedData);
            setIsLoadingCategory(false);
        });
    }, []);

    var colDefs = [
        { field: 'type', headerName: 'Type' },
        { field: 'venue_id', headerName: 'Venue ID' },
        { field: 'nga_event_venue', tooltipField: 'nga_event_venue', headerName: 'Venue', minWidth: 250 },
        { field: 'state', tooltipField: 'state', headerName: 'State', maxWidth: 50 },
        { field: 'city', tooltipField: 'city', headerName: 'City', minWidth: 100 },
        { field: 'tmid', tooltipField: 'tmid', headerName: 'Site ID', minWidth: 250 },
        {
            field: 'nga_event', tooltipField: 'nga_event', headerName: 'Event', minWidth: 250,
            cellRenderer: params => {
                return <a target='_blank' href={`${params.data.url}`} >{params.data.nga_event}</a>
            }
        },
        { field: 'section', tooltipField: 'section', headerName: 'Section' },
        {
            field: 'firstrow', headerName: 'First Row',
            cellEditor: NgaVenueConfigEditor,
            cellEditorParams: { setApplyMultipleVenues: setApplyMultipleVenues },
            editable: true,
            cellEditorPopup: true,
            cellEditorPopupPosition: 'over', minWidth: 60
        },
        { field: 'lastrow', headerName: 'Last Row', minWidth: 65 },
        { field: 'num_rows', type: 'numericColumn' },
        { field: 'by', headerName: 'By' },
        {
            field: 'last_update', headerName: 'Updated On',
            valueFormatter: params => { return dayjs.utc(params.value).fromNow(); },
        },
        {
            field: 'inserted', headerName: 'Inserted', minWidth: 150,
            valueFormatter: params => { return params.value ? formatDateTime(toLocalTime(params.value), 'MM/DD/YYYY hh:mm A') : null; }
        },
        { field: 'apply_to_venue', headerName: 'Apply to Venue', hide: true },

    ]

    const defaultColDef = useMemo(() => {
        return {
            sortable: true,
            resizable: true,
            flex: 1,
            filter: false,
            suppressMovable: true,
            singleClickEdit: true,
            autoSizeColumns: true
        };
    }, []);

    const handleSearch = (filterList) => {
        refInput.current.customFilterSearch(filterList);
    }

    const onDetailsGridReady = ((params) => {
        let defaultSortModel = [
            { colId: 'inserted', sort: 'desc', sortIndex: 0 }
        ];
        params.columnApi.applyColumnState({ state: defaultSortModel });
    });   
   
    const controls = [
        { component: 'select', name: 'type_eq', label: 'Type', choices: marketType },
        { component: 'number', name: 'venue_id_eq', label: 'Venue ID' },
        {
            component: 'select', name: 'edited_eq', label: 'Edited',
            choices: [
                { id: false, name: 'Not Edited' },
                { id: true, name: 'Edited' }
            ]
        },
        { component: 'text', name: 'nga_event_venue_contains', label: 'Venue' },
        { component: 'autocomplete', name: 'exclude_cities_ne', label: 'Exclude cities', xs: 3, choices: excludedCities },
        { component: 'text', name: 'tmid_contains', label: 'Site ID' },
        { component: 'text', name: 'nga_event_contains', label: 'Event' },
        { component: 'text', name: 'section_contains', label: 'Section', xs: 1.5 },
        { component: 'text', name: 'by_contains', label: 'By', xs: 1.5 },
        { component: 'autocomplete', name: 'exclude_unmapped_events_eq', label: 'Exclude unmapped events from market', xs: 2, choices: exchanges },
        { component: 'autocomplete', name: 'delisted_by_account_eq', label: 'Exclude delisted events from account', xs: 2, choices: accountMaps },
        { component: 'autocomplete', name: 'delisted_venue_by_account_eq', label: 'Exclude removed venues from account', xs: 2, choices: accountMaps },
        { component: 'checkbox', name: 'exclude_passed_eq', label: 'Exclude Past Events' },
        { component: 'checkbox', name: 'review_eq', label: 'Review Required', xs: 1 },
    ];

    return (
        <div style={containerStyle}>
            <Title title={'Row Range of SPEC'}></Title>
            <FilterListProvider>
                <RenderControls
                    filterInline={false}
                    defaultValues={defaultFilter}
                    controls={controls}
                    onSearch={handleSearch}
                    agSource={'NgaVenueConfig'}
                    agSort={defaultValues.sortField}                  
                    exportFile={{
                        filename: `RowRange-${formatLocalTime(new Date(), 'YY-MM-DD')}`,
                        columns: ['type', 'venue_id', 'nga_event_venue', 'state', 'city', 'tmid', 'nga_event', 'section', 'firstrow',
                            'lastrow', 'num_rows', 'by', 'last_update', 'apply_to_venue', 'id'],
                        limit: 1000000
                    }}
                />
                {isLoadingCategory && <p>Loading category ...</p>}
                <AgGrid
                    id={'spec-row-range'}
                    ref={refInput}
                    list={'NgaVenueConfig'}
                    columnDefs={colDefs}
                    defaultColDef={defaultColDef}
                    rowHeight={45}
                    rowSelection='single'
                    editType={'fullRow'}
                    defaultValues={defaultValues}// override default value 
                    setDefaultValues={setDefaultValues}
                    onGridReady={onDetailsGridReady}              
                ></AgGrid>
            </FilterListProvider>

            {applyVenues.open && <ApplyVenuesDialog refInputAggrid={refInput} applyVenues={applyVenues} setApplyMultipleVenues={setApplyMultipleVenues} marketType={marketType} />}
        </div>
    );
};

export default NgaVenueConfigList;