import React, { useState } from 'react';
import { Alert, Typography, Button, CircularProgress, Modal, Box } from '@mui/material';
import { useAuthProvider } from 'react-admin';
import { useMutation } from '@tanstack/react-query';
import { FileDownload, Close } from '@mui/icons-material';
const R2PrefixLength = 'https://r2.devsoft.workers.dev/'.length;
const apiUrl = process.env.REACT_APP_API_URL;
const download2 = async (authProvider, r2Url) => {
    let jwt = authProvider.getJWT();
    let r2Resource = r2Url.slice(R2PrefixLength);
    let response = await fetch(`${apiUrl}/r2/${r2Resource}`, {
        headers: {
            'authorization': `Bearer ${jwt}`,
        },
        method: 'GET',
    });
    var blob = await response.blob();
    return blob;
};
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
const SetupInstruction = ({ error: msgError }) => {
    const [blob, setBlob] = useState(null);
    const r2Url = 'https://r2.devsoft.workers.dev/SongPublic/BrowserStealth.48438.zip';
    const authProvider = useAuthProvider();
    const { mutate, isPending, isSuccess } = useMutation({
        mutationFn: () => download2(authProvider, r2Url).then(setBlob)
    });
    return <>
        <Typography variant="h4" component="h4">Stealth Service is not working</Typography>
        {msgError && <Alert severity="error">{msgError}</Alert>}
        <div>
            If you already installed the software, please run it.
        </div>
        <h2>Installation</h2>
        <h3>Download</h3>
        <div>
            <Button startIcon={<FileDownload />} size="medium" variant="outlined" onClick={mutate.bind(window)} disabled={isPending || isSuccess} > {isSuccess ? "Download Finished" : (isPending ? <CircularProgress /> : "Download")} </Button>
            <p>
                Click the Download button, and wait until it finished, then extract the archive to any folder that you like, then run the file 'stealth.exe' in that folder. After done, you should refresh this page.
            </p>
        </div>
        <h3>Run at start up</h3>
        <div>
            <section >
                <ol >
                    <li >
                        <p>With the file location open, press the <b >Windows logo key</b>&nbsp;<span ></span> + <b >R</b>, type <b >shell:startup</b>, then select <b >OK</b>. This opens the <b >Startup </b>folder.</p>
                    </li>
                    <li >
                        <p>Copy 'stealth.exe' from the previous folder and paste shortcut to the <b >Startup </b>folder.</p>
                    </li>
                </ol>
            </section>
            {/* <a href="https://support.microsoft.com/en-us/windows/add-an-app-to-run-automatically-at-startup-in-windows-10-150da165-dcd9-7230-517b-cf3c295d89dd">
                Run Browser Stealth at startup in Windows 10 / Windows 11
            </a> */}
        </div>
        {!!blob && <Modal
            open={true}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Save the download
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <Button startIcon={<FileDownload />} size="medium" variant="outlined"
                        href={URL.createObjectURL(blob)}
                        download={r2Url.split('/').pop()}> Save </Button>
                    <Button startIcon={<Close />} size="medium" variant="outlined" color='warning' onClick={() => setBlob(null)} >Close</Button>
                </Typography>
            </Box>
        </Modal>
        }
    </>;
};
export default SetupInstruction;
