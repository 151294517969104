import * as React from 'react';
import { matchPath } from 'react-router-dom';
import {
    Create, Edit, useNotify, useRefresh, useRedirect, TabbedForm, TextInput, required,
    NumberInput,
    Toolbar, SaveButton, ListButton, SelectInput,
    useCreate, useUpdate, DeleteWithConfirmButton, useRecordContext, minValue, maxValue, email
} from 'react-admin';
import { useFormContext } from 'react-hook-form'
import { Grid } from '@mui/material';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { TimeInput } from 'react-admin';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useState } from 'react';
import './style.css';
dayjs.extend(utc);
dayjs.extend(timezone);

const USTimeLabel = `You need select Team to watch current US time`;
const timeFormat = 'HH:mm';
const LATime = 'America/Los_Angeles';
const VNTime = 'Asia/Ho_Chi_Minh';
const PHTime = 'Asia/Manila';

const PostForm = ({ objFilter }) => {
    const isNew = matchPath('/user/create/*', location.pathname);
    const Form = () => {
        const record = useRecordContext();
        const redirect = useRedirect();
        const notify = useNotify();
        const refresh = useRefresh();
        const [updating] = !isNew ? useUpdate() : useCreate();
        const [userTeamId, setUserTeamId] = useState(record?.teamId || 0);

        const USTimeParser = (time, userLocation) => {
            const utcTimeFrom = dayjs.tz(time, timeFormat, userLocation).utc();
            const UStime = utcTimeFrom.tz(LATime);
            return UStime;
        };

        const GetHelperText = (time) => {
            if (time == '') return '...';
            const userLocation = userTeamId == 1 ? VNTime : PHTime;
            const timeFormat = USTimeParser(time, userLocation);
            return `US Time: ${timeFormat.format('h:mm A')}`;
        };

        const recordWorkingTime = !record
            ? [{ id: 0, From: '08:00', To: '17:00', FromHelpText: USTimeLabel, ToHelpText: USTimeLabel }]
            : record?.workingTimes !== ""
                ? JSON.parse(record.workingTimes).map((x, index) => ({ ...x, id: index, FromHelpText: GetHelperText(x.From), ToHelpText: GetHelperText(x.To) })) : [{ id: 0 }];
        const [timeRange, setTimeRange] = useState(recordWorkingTime);

        const sortByTime = (a, b) => {
            const timeFormat = 'HH:mm';
            const timeA = dayjs(a.From, timeFormat);
            const timeB = dayjs(b.From, timeFormat);
            return timeA.isBefore(timeB) ? -1 : timeA.isAfter(timeB) ? 1 : 0;
        };

        const handleSubmit = (data) => {
            Object.keys(data).map(key => {
                if (key.includes('from_') || key.includes('to_')) {
                    delete data[key];
                }
            });
            let sortedData = timeRange.slice().sort(sortByTime).map(({ id, ...x }) => x);
            data.workingTimes = (sortedData?.length === 1 && sortedData[0].From == '' && sortedData[0].To == '') ? null : JSON.stringify(sortedData);

            updating('User', { id: data.id, data: data },
                {
                    onSuccess: () => {
                        notify('Changes updated', { type: 'success' });
                        redirect('/User');
                        refresh();
                    },
                    onError: (error) => {
                        notify(`Save fail: ${error.message}`, { type: 'error' });
                    }
                }
            );
        };

        const MyToolbar = () => {
            return (
                <Toolbar>
                    <SaveButton />
                    <ListButton />
                    {
                        !isNew && <DeleteWithConfirmButton translateOptions={{ name: record.id }} />
                    }
                </Toolbar>
            );
        };

        const formatUSTimeZone = (time, teamId) => {
            if (isNew && !teamId) return [];
            let USTime = time;
            dayjs.extend(utc);
            dayjs.extend(timezone);
            setTimeRange(prev => (
                prev.map(x => {
                    const userLocation = teamId == 1 ? VNTime : PHTime;
                    const timeFrom = USTimeParser(x.From, userLocation);
                    const timeTo = USTimeParser(x.To, userLocation);
                    return { ...x, FromHelpText: `US Time: ${timeFrom.format('h:mm A')}`, ToHelpText: `US Time: ${timeTo.format('h:mm A')}` };
                }))
            );

            return USTime;

        };

        const hanldeChangeTeam = (event, data, temp) => {
            const USTime = formatUSTimeZone(timeRange, event.target.value);
            setUserTeamId(event.target.value);
        };

        return (
            <TabbedForm defaultValues={({ market: 0 })} toolbar={<MyToolbar />} mode="onBlur" reValidateMode="onBlur" onSubmit={handleSubmit}>
                <TabbedForm.Tab label="User" sx={{ display: 'flex !important' }}>
                    <Grid container item xs={12} columnSpacing={2}>
                        <Grid item xs={6}>
                            <TextInput source="email" label="Email" validate={[required(), email()]} resettable />
                        </Grid>
                        <Grid item xs={6}>
                            <TextInput source="aliasName" label="Alias Name" validate={required()} resettable />
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} columnSpacing={2}>
                        <Grid item xs={4}>
                            <TextInput source="firstName" label="First Name" resettable />
                        </Grid>
                        <Grid item xs={4}>
                            <TextInput source="midName" label="Mid Name" resettable />
                        </Grid>
                        <Grid item xs={4}>
                            <TextInput source="lastName" label="Last Name" resettable />
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} columnSpacing={2}>
                        <Grid item xs={6}>
                            <TextInput source="fullName" label="Full Name" resettable />
                        </Grid>
                        <Grid item xs={6}>
                            <TextInput source="phone" label="Phone" resettable />
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} columnSpacing={2}>
                        <Grid item xs={3}>
                            <SelectInput validate={required()} label="Team" source="teamId"
                                choices={objFilter.Team}
                                translateChoice={false} resettable fullWidth onChange={(event, data, temp) => hanldeChangeTeam(event, data, temp)} />
                        </Grid>
                        <Grid item xs={3}>
                            <NumberInput step={0.1} source="indicator" label="Indicator" validate={[minValue(0), maxValue(1)]} max={1} min={0} />
                        </Grid>
                        <Grid item xs={3}>
                            <NumberInput source="order" label="Order" />
                        </Grid>
                        <Grid item xs={3}>
                            <SelectInput validate={required()} label="Status" source="status"
                                choices={objFilter.Status}
                                translateChoice={false} resettable />
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} columnSpacing={2}>
                    </Grid>
                    <MyTimePicker timeRange={timeRange} setTimeRange={setTimeRange} GetHelperText={GetHelperText} />
                </TabbedForm.Tab>
            </TabbedForm>
        );
    };

    return isNew ? (<Create ><Form /></Create>) : (<Edit ><Form /></Edit>);
}

const MyTimePicker = (params) => {
    const { timeRange, setTimeRange, GetHelperText } = params;
    const { clearErrors, setValue } = useFormContext();

    const handleWorkTime = (event, action, id) => {
        switch (action) {
            case 'from':
                setTimeRange(prev => prev.map((item) => (item.id === id ? { ...item, From: event.target.value, FromHelpText: GetHelperText(event.target.value) } : item)));
                break;
            case 'to':
                setTimeRange(prev => prev.map((item) => (item.id === id ? { ...item, To: event.target.value, ToHelpText: GetHelperText(event.target.value) } : item)));
                break;
            case 'add':
                const cloneRange = timeRange.find((x) => x.id === id);
                setTimeRange(prev => [...prev, { ...cloneRange, id: timeRange[timeRange.length - 1].id + 1 }]);
                break;
            case 'remove':
                setTimeRange(prev => (prev.length <= 1 ?
                    prev.map(item => (item.id === id ? { ...item, From: '', To: '', FromHelpText: '...', ToHelpText: '...' } : item))
                    : prev.filter(x => x.id !== id)));
                if (timeRange.length == 1) {
                    setValue(`from_${id}`, '');
                    setValue(`to_${id}`, '');
                }
                break;
        }

    };


    const validateWorkingTimes = (value, allValues, data) => {
        if (allValues[`from_${data.validateid}`] == null && allValues[`to_${data.validateid}`] == null) {
            clearErrors(`from_${data.validateid}`);
            clearErrors(`to_${data.validateid}`);
        }
        let comparePrefix = data.source.includes('from') ? 'to' : 'from';
        const compareValue = allValues[`${comparePrefix}_${data.validateid}`];
        const timeFormat = 'HH:mm';
        const compareTime = typeof (compareValue) == 'object' ? dayjs(compareValue) : dayjs(compareValue, timeFormat);
        const checkTime = dayjs(data.value, timeFormat);
        if (!checkTime.isValid() && !compareTime.isValid()) return undefined;
        if (!checkTime.isValid()) return 'Please complete the start and end time';
        if (checkTime.isValid() && compareTime.isValid()) {
            if (comparePrefix === 'to' && compareTime.isBefore(checkTime)) {
                return 'The start time must be less than end time';
            } else if (comparePrefix === 'from' && compareTime.isAfter(checkTime)) {
                return 'The end time must be greater than end time';
            }
            clearErrors(`${comparePrefix}_${data.validateid}`);//clear error in compare time if checkTime is ok
        }

        return undefined;
    };

    return (
        <>
            {timeRange?.map((item, index) =>
                <Grid container item xs={12} columnSpacing={2} key={item.id}>
                    <Grid item xs={3}>
                        <TimeInput
                            className="my-custom-class"
                            defaultValue={item.From || null}
                            value={item.From}
                            source={`from_${item.id}`}
                            label="From"
                            validateid={item.id}
                            onChange={(event) => handleWorkTime(event, 'from', item.id)}
                            onError={(err) => console.log('error:', err)}
                            validate={validateWorkingTimes}
                            helperText={item.FromHelpText}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TimeInput
                            defaultValue={item.To || null}
                            value={item.To}
                            source={`to_${item.id}`}
                            label="To"
                            validateid={item.id}
                            onChange={(event) => handleWorkTime(event, 'to', item.id)}
                            validate={validateWorkingTimes}
                            helperText={item.ToHelpText}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Stack direction="row" spacing={1} sx={{ marginTop: '15px' }}>
                            <Button variant="contained" onClick={(event) => handleWorkTime(item, 'add', item.id)} endIcon={<AddCircleOutlineIcon />}>Add</Button>
                            <Button disabled={timeRange.length <= 1} variant="contained" onClick={(event) => handleWorkTime(item, 'remove', item.id)} endIcon={<DeleteOutlineIcon />}>Remove</Button>
                        </Stack>
                    </Grid>
                </Grid>
            )}
        </>
    )
}
export default PostForm;