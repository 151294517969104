import React from 'react';
import MDialog from '../../../components/controls/MDialog';
import { Checkbox, FormControlLabel, Grid, TextField } from '@mui/material';
import { toLocalTime } from '../../../components/ex_dayjs';
import { formatEnumToName } from '../../../utils/common';

const DetailsView = (props) => {
    const { detailPopup, setDetailPopup } = props;
    const record = detailPopup.data;
    return (
        <>
            <MDialog
                maxWidth={'md'}
                open={detailPopup.open}
                handleClose={() => setDetailPopup(prev => ({ ...prev, open: false }))}
                title={'Event Process Tracking'}
                name={'process-tracking-dialog'}
                children={
                    <Grid container columnSpacing={{ xs: 1 }} >
                        <Grid item xs={6}>
                            <TextField value={record.type} label='Market' fullWidth />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField value={record.order_Number} label='Order Num' fullWidth />
                        </Grid>
                        
                        <Grid item xs={6}>
                            <TextField value={record.event} label='Event' fullWidth />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField value={record.venue} label='Venue' fullWidth />
                        </Grid>
                       
                        <Grid item xs={12}>
                            <TextField value={record.error} label='Error' fullWidth />
                        </Grid>       

                        <Grid item xs={6}>
                            <TextField value={record.author} label='Author' fullWidth />
                        </Grid>                      
                        <Grid item xs={6}>
                            <TextField value={toLocalTime(record.utc_Posted_On).format('MM/DD/YYYY hh:mm A')} label='Date' fullWidth />
                        </Grid>

                        <Grid item xs={4}>
                            <TextField value={record.action} label='Action' fullWidth />
                        </Grid>                      
                        <Grid item xs={4}>
                            <TextField value={record.modified_By} label='Modified By' fullWidth />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField value={toLocalTime(record.modified).format('MM/DD/YYYY hh:mm A')} label='Modified' fullWidth />
                        </Grid>
                        
                    </Grid>
                }
            >
            </MDialog>
        </>
    );
}
export default DetailsView