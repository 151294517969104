import React, { useState } from "react";
import { Button, useNotify } from "react-admin";
import MBackdrop from "../../../components/controls/MBackdrop";
import AutocompleteConfirm from "./AutocompleteConfirm";

const UnmapAllButon = ({ params, dataProvider, selectedIds, reloadPage }) => {
    const notify = useNotify();

    const [state, setState] = useState({
        selectedIds: [],
        unmap_isOpen: false,
        unmap_sync_isLoading: false
    });

    const handleClose = () => {
        setState((prev) => ({ ...prev, unmap_isOpen: false }));
    };

    const confirmUnmapAll = () => {
        if (selectedIds.length) {
            setState((prev) => ({ ...prev, unmap_isOpen: true, selectedIds }));
        } else {
            notify('Please select event to unmap!!!', { type: 'warning' });
        }
    };

    const handleUnmapAll = (reason) => {
        handleClose();
        let data = params.data;
        var dataSelected = data.filter(x => selectedIds.find(y => y == x.id));
        if (dataSelected.length) {
            setState((prev) => ({ ...prev, unmap_sync_isLoading: true }));
            dataProvider.currentUser().then((user) => {
                if (user.email) {
                    dataSelected.forEach((e) => {
                        e.EventID = e.id;
                        e.sm_event_id = e.smEventID;
                        e.sm_event_name = e.smEvent;
                        e.sm_market_id = e.marketId;
                        e.mapped = true;
                        e.ReasonTags = reason;
                        e.json = JSON.stringify({
                            Name: e.name,
                            DateTime: e.dateTime,
                            Venue: e.venue,
                            City: e.city,
                            State: e.state,
                            SmName: e.smEvent,
                            SmDateTime: e.smDateTime,
                            SmVenue: e.smVenue,
                            SmCity: e.smCity,
                            SmState: e.smState,
                            Score: e.score,
                            MarketId: e.marketId,
                            Mapped_By: user.email,
                            Mapped_Time: new Date()
                        });
                    });
                    dataProvider.postData(params.resource, `UnmapEvents`, dataSelected)
                        .then((response) => {
                            if (response && response.data) {
                                if (response.data === true) {
                                    notify(`All the events were unmapped!!!`, { type: 'success' });
                                    setState((prev) => ({ ...prev, unmap_sync_isLoading: false }));
                                    reloadPage();
                                } else notify(`${response.data}`, { type: 'error' });
                            }
                        })
                        .catch((error) => notify(`Error: ${error}`, { type: 'error' }));
                }
            });
        }
    };

    return (
        <>
            <Button variant="contained" size="small" onClick={confirmUnmapAll}>Unmap</Button>
            <AutocompleteConfirm
                isOpen={state.unmap_isOpen}
                title={`Are you sure to unmap all the selected (${state.selectedIds.length}) records?`}
                onClose={handleClose}
                onConfirm={(reason) => handleUnmapAll(reason)}
            />
            <MBackdrop isOpen={state.unmap_sync_isLoading} message={'Unmapping....'} />
        </>
    );
};

export default React.memo(UnmapAllButon);