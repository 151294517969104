import * as React from 'react';
import { TextField, Typography, Skeleton } from '@mui/material';
import MQueryBuilder from '../../../components/query-builder/MQueryBuilder';
import { defaultExcludeForNumber, defaultExcludeForString, getExtractDate } from '../../../components/query-builder/querybuilder.config';
import { QueryBuilderMaterial } from '@react-querybuilder/material';


const RuleCreator = ({ isEdit, editData, handleChange, query, actionQueryRef, conditionQueryRef, onDataChange, valid , marketType, delivery, account}) => {
    const isLoad = isEdit && !editData;
    let formatedCondition = editData?.condition && JSON.parse(editData.condition);
    let formatedAction = editData?.action && JSON.parse(editData.action);

    const validator = (r) => !!r.value || r.value.lenght > 0;
    const conditionFields = [
        { id: 'event', name: 'event', label: 'Event', input: 'text', type: 'string', excludes: defaultExcludeForString, validator },
        { id: 'venue', name: 'venue', label: 'Venue', input: 'text', type: 'string', excludes: defaultExcludeForString, validator },
        { id: 'primary_url', name: 'primary_url', label: 'Primary URL', input: 'text', type: 'string', excludes: defaultExcludeForString, validator },
        { id: 'vivid_account_id', name: 'vivid_account_id', label: 'Account', defaultValue: '1', values: account, input: 'select', type: 'integer', operators: [{ name: 'equal', label: 'equal' }], validator, valueEditorType: 'select' },
        { id: 'type', name: 'type', label: 'Event market', defaultValue: 'accesso', values: marketType, input: 'select', type: 'string', validator, valueEditorType: 'select' },
        { id: 'delivery_method_value', name: 'delivery_method_value', label: 'Delivery method', defaultValue: '0', values: delivery, input: 'select', type: 'integer', operators: [{ name: 'equal', label: 'equal' }], validator, valueEditorType: 'select' },
        { id: 'pricelevel', name: 'pricelevel', label: 'Price Level', input: 'text', type: 'string', excludes: defaultExcludeForString, validator, defaultOperator: 'contains' },
        {
            id: 'date', name: 'date', label: 'Event Date', input: 'text', type: 'date',
            placeholder: 'yyyy-MM-dd, Now.AddDays(1), Now.AddMonths(1), Now.AddYears(1)',
            validator: (r) => {
                if (Array.isArray(r.value)) {
                    for (let i = 0; i < r.value.length; i++) {
                        if (!r.value[i] || !getExtractDate(r.value[i])) return false;
                    }
                    return true;
                } else return getExtractDate(r.value) != null;
            }
        },
        { id: 'order_total', name: 'order_total', label: 'Order Total', input: 'number', inputType: 'number', type: 'integer', excludes: defaultExcludeForNumber },
        { id: 'quantity', name: 'quantity', label: 'Quantity', input: 'number', inputType: 'number', type: 'integer', excludes: defaultExcludeForNumber },
        { id: 'row', name: 'row', label: 'Row', input: 'text', type: 'string', excludes: defaultExcludeForString },
        { id: 'tags', name: 'tags', label: 'Tags', input: 'text', type: 'string', excludes: defaultExcludeForString }
    ];

    const actionFields = [
        {
            id: 'tags', name: 'tags', label: 'Tags', input: 'text', type: 'string', operators: [{ name: 'assign', label: 'assign' }], placeholder: '=input.AddTag("Tag1,Tag2")', validator
        },
        { id: 'notes_by_rules', name: 'notes_by_rules', label: 'Notes', input: 'text', type: 'string', operators: [{ name: 'assign', label: 'assign' }], placeholder: '=input.AddNote("Human notes")', validator },
        { id: 'delivery_delay', name: 'delivery_delay', label: 'Delivery Delay', input: 'number', inputType: 'number', type: 'integer', defaultValue: 7, operators: [{ name: 'assign', label: 'assign' }], validator },
    ];

    const conditionInitData = {
        combinator: 'AND',
        rules: [ 
        ]
    }
    const actionInitData = {
        combinator: 'modify',
        rules: [
        ]
    }

    const combinators = [
        { name: 'modify', label: '' }
    ];
    return (
        <>
            <Typography variant="h5" gutterBottom flex="1" key='name'>
                Rule Name
            </Typography>
            {isLoad ? <Skeleton /> : <TextField
                value={editData?.name || ''}
                error={!valid}
                fullWidth
                required
                label="Rule"
                onChange={onDataChange}
                helperText={!valid ? "Name can not be empty" : null}
                placeholder="eg. Ban venue abc..." variant="outlined" />}
            <Typography variant="h6" gutterBottom flex="1" key='math'>
                For those orders match ...
            </Typography>
            <QueryBuilderMaterial>
                <>
                    {isLoad ? <Skeleton /> :
                        <MQueryBuilder
                            key='condition'
                            fields={conditionFields}
                            initialData={conditionInitData}
                            ref={conditionQueryRef}
                            editData={formatedCondition}
                            defaultOperator='and'
                            query={query}
                            handleChange={handleChange}
                            hideNotButton />}
                </>
                <Typography variant="h6" flex="1" key='adjust'>
                    Then adjust
                </Typography>
                <>
                    {isLoad ? <Skeleton /> :
                        <MQueryBuilder
                            key='action'
                            fields={actionFields}
                            initialData={actionInitData}
                            ref={actionQueryRef}
                            editData={formatedAction}
                            defaultOperator='modify'
                            query={query}
                            hideGroupButton
                            hideLockButtons
                            hideNotButton
                            handleChange={handleChange}
                            combinators={combinators}
                            combinatorName='method'
                        />}
                </>
            </QueryBuilderMaterial>
        </>
    );
};
export default React.memo(RuleCreator);
