import DivvyPage from './divvy';
import EmailPage from './email';
import SourceVariablePage from './sourcevariable';
import ListingVariablePage from './list-variable';
import PrimaryAccountPage from './admins/primaryaccount';
import OrderSummaryReportPage from './reports/OrderSummaryReport';
import OrderEntryReportPage from './reports/OrderEntryReport';
import ListingsPage from './listings';
import EventDeliveryPage from './event-delivery';
import EventMatcherPage from './eventmatcher';
import NgaVenueConfigPage from './nga-venue-config';
import OrderDetailPage from './order-detail/order-detail-view';
import ListingsMappingPage from './listings-mapping';
import ShippingReportPage from './shipping-report';
import OneCallNowPage from './one-call-now';
import TNSalesAnalysisPage from './tnsale-analysis/index';
import OrderAdjustRulePage from './order-adjust-rule';
import DynamicAdjustRulePage from './dynamic-adjust-rule';
import LMUnderservedEventsPage from './reports/LMUnderservedEvents/index';
import TnCompetitiveAnalysisPage from './tn-competitive-analysis';
import ReceiptsCounterPage from './receipts-counter/index';
import MinuteByMinutePage from './minute-by-minute';
import CsvMergePage from './csv-merge';
import TNHourlyDataPage from './tn-hourly-data';
import TicketSearchPage from './ticket-search';
import TMProcessingReportPage from './tm-processing-report';
import SmEventTicketsPage from './smevent-tickets';
import VVPerformanceTrackingPage from './vv-performance-tracking';
import WeeklyWorkTimePage from './weekly-work-time';
import UserPage from './users';
import OrderAssignmentsPage from './order-assignments';
import UserDashboardPage from './user-dashboard';
import ProcessTrackingReportPage from './process-tracking-report';
import OrderMistakeReportPage from './order-mistake-report';
import MappingErrorReportPage from './reports/mapping-error-report';
import SmEventCheckPage from './smevent-check';
import OrderAutoPriceReportPage from './order-autoprice-report';
import AvailabilityPage from './availability';
import AATPrimaryAccountPage from './aat-primary-accounts';
import VividOrderPage from './orders/templates/vivid';
import TicketNetworkOrderPage from './orders/templates/ticketnetwork';
import GoTicketsOrderPage from './orders/templates/gotickets';
import EmbursePage from './emburse';
import ProblemOrderTrackingReportPage from './problem-order_tracking-report';

const resources = [
    DivvyPage,
    EmailPage,
    ListingsPage,
    ListingsMappingPage,
    SourceVariablePage,
    ListingVariablePage,
    PrimaryAccountPage,
    OrderSummaryReportPage,
    EventDeliveryPage,
    NgaVenueConfigPage,
    OrderDetailPage,
    OrderEntryReportPage,
    EventMatcherPage,
    ShippingReportPage,
    OneCallNowPage,
    TNSalesAnalysisPage,
    OrderAdjustRulePage,
    DynamicAdjustRulePage,
    LMUnderservedEventsPage,
    CsvMergePage,
    TnCompetitiveAnalysisPage,
    TNHourlyDataPage,
    ReceiptsCounterPage,
    TicketSearchPage,
    TMProcessingReportPage,
    MinuteByMinutePage,
    SmEventTicketsPage,
    VVPerformanceTrackingPage,
    WeeklyWorkTimePage,
    UserPage,
    OrderAssignmentsPage,
    UserDashboardPage,
    ProcessTrackingReportPage,
    OrderMistakeReportPage,
    MappingErrorReportPage,
    SmEventCheckPage,
    OrderAutoPriceReportPage,
    AvailabilityPage,
    AATPrimaryAccountPage,
    VividOrderPage,
    TicketNetworkOrderPage,
    GoTicketsOrderPage,
    EmbursePage,
    ProblemOrderTrackingReportPage
];

export default resources;

