const _ = require('lodash');
import { defaultExcludeForNumber, defaultExcludeForString, getExtractDate, validateExpression } from "../../../components/query-builder/querybuilder.config";
import { convertStringToArray } from "../utils";

const conditionFields = [
    { id: 'Event', name: 'Event', label: 'Event', input: 'text', type: 'string', excludes: defaultExcludeForString },
    { id: 'Venue', name: 'Venue', label: 'Venue', input: 'text', type: 'string', excludes: defaultExcludeForString },
    { id: 'EventUrl', name: 'EventUrl', label: 'EventUrl', input: 'text', type: 'string', excludes: defaultExcludeForString },
    {
        id: 'EventDate', name: 'EventDate', label: 'EventDate', input: 'text', type: 'date',
        placeholder: 'yyyy-MM-dd, Now.AddDays(1), Now.AddMonths(1), Now.AddYears(1)',
        validator: (r) => {
            if (Array.isArray(r.value)) {
                for (let i = 0; i < r.value.length; i++) {
                    if (!r.value[i] || !getExtractDate(r.value[i])) return false;
                }
                return true;
            } else return getExtractDate(r.value) != null;
        }
    },
    { id: 'EventTime', name: 'EventTime', label: 'EventTime', input: 'text', type: 'date', inputType: 'time' },
    { id: 'Quantity', name: 'Quantity', label: 'Quantity', input: 'number', inputType: 'number', type: 'integer', excludes: defaultExcludeForNumber },
    // { id: 'SoldQuantity', name: 'SoldQuantity', label: 'SoldQuantity', input: 'number', inputType: 'number', type: 'integer', excludes: defaultExcludeForNumber },
    { id: 'Section', name: 'Section', label: 'Section', input: 'text', type: 'string', excludes: defaultExcludeForString },
    { id: 'Row', name: 'Row', label: 'Row', input: 'text', type: 'string', excludes: defaultExcludeForString },
    { id: 'Cost', name: 'Cost', label: 'Cost', input: 'text', type: 'string', excludes: ['between', 'not_between'] },
    { id: 'InHandDate', name: 'InHandDate', label: 'InHandDate', input: 'text', type: 'date', inputType: 'date' },
    { id: 'DeliveryMethod', name: 'DeliveryMethod', label: 'DeliveryMethod', input: 'text', type: 'string', excludes: defaultExcludeForString },
    { id: 'TicketID', name: 'TicketID', label: 'Ticket ID', input: 'text', type: 'string', excludes: defaultExcludeForString },
    { id: 'PriceLevel', name: 'PriceLevel', label: 'PriceLevel', input: 'text', type: 'string', excludes: defaultExcludeForString },
    { id: 'EventId', name: 'EventId', label: 'EventId', input: 'number', inputType: 'number', type: 'integer', excludes: defaultExcludeForNumber },
    { id: 'City', name: 'City', label: 'City', input: 'text', type: 'string', excludes: defaultExcludeForString },
    { id: 'State', name: 'State', label: 'State', input: 'text', type: 'string', excludes: defaultExcludeForString },
    {
        id: 'Notes', name: 'Notes', label: 'Notes', input: 'text', type: 'string',
        placeholder: 'Notes DO NOT HAVE comma (,)',
        excludes: defaultExcludeForString,
        validator: (r) => r.value && /^((?!,).)*$/.test(r.value)
    }
];

const conditionInitData = {
    combinator: 'AND',
    rules: []
};
const actionInitData = {
    combinator: 'modify',
    rules: []
};

const combinators = [
    { name: 'modify', label: '' }
];

function getActionFields() {
    let arr = _.cloneDeep(conditionFields);
    arr.push({ id: 'UNITTAXEDCOST', name: 'UNITTAXEDCOST', label: 'UNITTAXEDCOST', input: 'text', type: 'string' });
    arr.push({ id: 'FaceValue', name: 'FaceValue', label: 'FaceValue', input: 'text', type: 'string' });
    arr.push({
        id: 'FloorPrice', name: 'FloorPrice', label: 'FloorPrice', input: 'text', type: 'string',
        placeholder: '=x + 10, =x*1.1, =input.Field1 where x is FloorPrice',
        validator: (r) => validateExpression(r),
    });
    arr.push({ id: 'IsShort', name: 'IsShort', label: 'IsShort', input: 'number', type: 'integer' });
    return arr.map((m) => {
        if (m.type == 'date' || m.type == 'integer') {
            m.type = 'string';
            delete m.inputType;
        }
        m.operators = [{ name: 'assign', label: 'assign' }];
        if (m.name == 'Event') m.placeholder = '=x.Replace("Event","NewEvent") where x is Event';
        else if (m.name == 'Venue') m.placeholder = '=x.Replace("Venue","NewVenue") where x is Venue';
        else if (m.name == 'Quantity') {
            m.placeholder = '=x + 10, =x*1.1, =input.Field1 where x is Quantity';
            m.validator = (r) => validateExpression(r);
        }
        else if (m.name == 'InHandDate') m.placeholder = '=x.AddDays(1), =x.AddMonths(1), =x.AddYears(1) where x is InHandDate';
        else if (m.name == 'EventDate') m.placeholder = '=x.AddDays(1), =x.AddMonths(1), =x.AddYears(1) where x is EventDate';
        else if (m.name == 'Cost') {
            m.placeholder = '=x + 10, =x*1.1, =input.Field1 where x is Cost';
            m.validator = (r) => validateExpression(r);
        }
        else if (m.name == 'Notes') m.placeholder = 'NO COMMA (,) in the notes or CSV LISTING break';
        else if (m.name == 'Section') m.placeholder = '=x.Replace("Section","NewSection") where x is Section';
        else if (m.name == 'Row') m.placeholder = '=x.Replace("Row","NewRow") where x is Row';
        return m;
    });
}

function getConditionFields() {
    let arr = _.cloneDeep(conditionFields);
    arr.push({ id: 'Type', name: 'Type', label: 'Type', input: 'text', type: 'string' });
    return arr;
}

function getRule(idAndData) {
    let id = idAndData;
    let data = undefined;

    if (idAndData instanceof Array) {
        id = idAndData[0];
        data = idAndData[1];
    }

    if (id < 0 && data != undefined) {
        return {
            Condition: buildConditions(data),
            Action: data.action || { method: 'remove', rules: [] },
            Name: data['name'],
            Id: 0,
            CreatedBy: '',
            Timestamp: '',
            Tags: data['tags']?.filter(x => x),
            Priority: data['priority'],
            ExpiredDate: data['expiredDate'],
            Source: (convertStringToArray(data["sources"]) || []).join(',')
        };
    }
    return null;

    function buildConditions(data) {
        let rules = [];
        if (data['event'] != undefined) rules.push({
            id: 'Event',
            field: 'Event',
            type: 'string',
            input: 'text',
            operator: 'contains',
            value: data['event']
        });
        if (data['venue'] != undefined) rules.push({
            id: 'Venue',
            field: 'Venue',
            type: 'string',
            input: 'text',
            operator: 'contains',
            value: data['venue']
        });
        if (data['section'] != undefined) rules.push({
            id: 'Section',
            field: 'Section',
            type: 'string',
            input: 'text',
            operator: 'equal',
            value: data['section']
        });
        if (data['row'] != undefined) rules.push({
            id: 'Row',
            field: 'Row',
            type: 'string',
            input: 'text',
            operator: 'equal',
            value: data['row']
        });
        if (data['eventDate'] != undefined) rules.push({
            id: 'EventDate',
            field: 'EventDate',
            type: 'date',
            input: 'text',
            operator: 'equal',
            value: data['eventDate']
        });
        if (data['eventTime'] != undefined) {
            rules.push({
                id: 'EventTime',
                field: 'EventTime',
                type: 'date',
                input: 'text',
                operator: 'equal',
                value: data['eventTime']
            });
        }

        if (data['quantity'] != undefined) rules.push({
            id: 'Quantity',
            field: 'Quantity',
            type: 'string',
            input: 'text',
            operator: 'greater_or_equal',
            value: data['quantity']
        });

        if (rules.length == 0) return null;

        return { "conditions": "AND", "rules": rules };
    }
}

export {
    combinators,
    actionInitData,
    conditionInitData,
    getActionFields,
    getConditionFields,
    getRule,
    getExtractDate
};