import React, { useMemo, useEffect, useState, useRef } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { useNotify, Title } from 'react-admin';
import { dataProvider } from '../../DataProvider';
import { formatDateTime, formatLocalTime } from "../../components/ex_dayjs";
import AgGrid from '../../components/ag-grid/ag-grid';
import RenderControls from '../../components/ra-list/RenderControls';
import { getRaStore, loadExchanges, loadSourceType, setDefaultValuesFromURL } from '../../utils/common';
import { Countries } from "../../utils/enum";
import { Button, Tooltip, Link, useColorScheme } from '@mui/material';
import MultiSelectTags from './components/MultiSelectTags';
import Tags from './components/Tags';
import { blue, grey, red, green, deepOrange } from "@mui/material/colors";
import { styled } from '@mui/material/styles';
import { reloadPage } from '../../utils/common';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxHeader from './components/CheckBoxHeader';
import ExactMatch from './components/ExactMatchHeader';
import './style.css';
import { dayjs } from '../../components/ex_dayjs';

const SelectButton = styled(Button)(() => ({
  background: '#f0ad4e',
  color: 'white',
  borderColor: '#eea236',
  '&:hover': {
    background: '#ec971f',
    borderColor: '#d58512'
  }
}))

const ApproveButton = styled(Button)(() => ({
  background: '#5cb85c',
  color: 'white',
  borderColor: '#4cae4c',
  marginLeft: '10px',
  '&:hover': {
    background: '#449d44',
    borderColor: '#398439'
  }
}))

let highlightCell = true;


const EventMatcherList = () => {
  const notify = useNotify();
  const emptyEventMessage = (message) => notify(message || `Please select event`, { type: 'warning', autoHideDuration: 60000 }, { anchorOrigin: { vertical: 'top', horizontal: 'right' } });
  const [tagSelected, setTagsSelected] = useState([]);
  const [remove, setRemove] = useState(false);
  const refbtnFilter = useRef(null);
  const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
  const gridRef = useRef();
  const eventRef = useRef();
  const venueRef = useRef();
  const cityRef = useRef();
  const stateRef = useRef();
  const dateRef = useRef();

  const { mode } = useColorScheme();
  const oneScoreStyle = mode == 'light' ? { backgroundColor: 'orange', color: 'black', padding: '2px' } : { backgroundColor: 'orange', color: 'white', padding: '2px' };
  const zeroScoreStyle = { backgroundColor: '#ff0000b8', color: 'white', padding: '2px' };


  const getStyles = (tagName) => {
    switch (tagName) {
      case 'high value':
        return green[500];
      case 'low value':
        return red[600];
      case 'support ticket':
        return blue[500];
      case 'skip':
      case 'unmapped':
      case 'manual review':
        return grey[500];
      default:
        return deepOrange[400];
    }
  }

  const defaultTags = ['New', 'Procesing', 'High value', 'Low value', 'Request from exchange', 'Support Ticket', 'Skip', 'Manual Review', 'Unmapped', 'Ontario', 'Vegas', 'Request Again'];
  let doLoadData = true;
  const [marketType, setMarketType] = useState([]);
  const [exchange, setExchange] = useState([]);

  const [defaultValueFilter, setDefaultValuesFilter] = useState({
    MarketId_eq: 1,
    SkipTag: true,
    ManualReviewTag: true,
    AdminSkip: false,
    Score: [2, 5]
  });

  const handleSelection = (e) => {
    e && setTagsSelected(e.value);
  }

  const SetHighLight = (isHighLight) => {
    highlightCell = isHighLight;
    gridRef.current.getAgGridInstance().api.redrawRows();
  }

  const controls = [
    { component: 'number', name: 'TicketId', label: 'Ticket ID' },
    { component: 'number', name: 'SmOriginalID', label: 'Sm EventID' },
    { component: 'select', name: 'Type_eq', label: 'Type', choices: marketType },
    { component: 'select', name: 'Country', label: 'Country', choices: Countries },
    { component: 'text', name: 'Name', label: 'Event' },
    { component: 'text', name: 'Venue', label: 'Venue' },
    { component: 'daterange', name: 'DateTime', label: 'Date Time' },
    { component: 'numberrange', name: 'Score', label: 'Score', xs: 2, decimal: 3 },
    { component: 'select', name: 'MarketId_eq', label: 'Exchange market', required: true, isEmptyOption: false, xs: 1.5, choices: exchange.map(x => ({ id: Number(x.id), name: x.name })) },
    { component: 'autocomplete', name: 'Tags_contains', label: 'Tags', xs: 3, choices: defaultTags, getStyles: getStyles, onChange: handleSelection },
    { component: 'checkbox', name: 'SkipTag', label: 'Skip tags', },
    { component: 'checkbox', name: 'ManualReviewTag', label: 'Manual Review', xs: 1.3 },
    { component: 'checkbox', name: 'AdminSkip', label: 'Admin Tagged', xs: 1.5 }
  ];

  useEffect(() => {
    if (doLoadData) {
      loadSourceType(dataProvider, (response) => {
        setMarketType(response);
      })

      loadExchanges(dataProvider, (response) => {
        setExchange(response);
      })
      doLoadData = false;
    }

    if (location.search) {
      var newDefaultValue = { ...defaultValueFilter };
      setDefaultValuesFromURL(location.search, newDefaultValue, controls);
      if (newDefaultValue.EventId) {
        newDefaultValue.id = parseInt(newDefaultValue.EventId);
        delete newDefaultValue.EventId;
      }
      setDefaultValuesFilter(newDefaultValue);
      setTimeout(() => refbtnFilter.current.click(), 500);
    }
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      cellStyle: { wordBreak: 'normal', fontSize: '14px' },
      singleClickEdit: true,
      sortable: true,
      wrapText: true,
      filter: false,
      autoHeight: true,
      flex: 1,
      suppressRowHoverHighlight: true,
      suppressHorizontalScroll: true,
      suppressMovable: true,//block move columns
      resizable: true,
      autoHeaderHeight: true,
    };
  }, []);

  const ragRenderer = (params) => {
    switch (params.column?.colId) {
      case 'dateTime':
      case 'smDateTime':
        let _date = dayjs(params.data.dateTime);
        let _smdate = dayjs(params.data.smDateTime);
        let isSameDay = _date.isSame(_smdate, 'day');
        let isSameTime = _date.isSame(_smdate, 'hour') && _date.isSame(_smdate, 'minute');
        let validRange = Math.abs(_date.diff(_smdate, 'hour')) == 1
        return <span style={highlightCell ? ((isSameDay && isSameTime) ? {} : ((isSameDay && validRange) ? oneScoreStyle : zeroScoreStyle)) : {}}>{formatDateTime(params.value)}</span>;
      case 'name':
        return (<Link href={params.data.url} underline="hover" target="_blank">
          <span style={!highlightCell ? undefined : params.data.s_Event == 0 ? zeroScoreStyle : params.data.s_Event < 1 ? oneScoreStyle : {}}>{params.value}</span>
        </Link>)
      case 'smEvent':
        return (<Link href={params.data.smUrl} underline="hover" target="_blank">
          <span style={!highlightCell ? undefined : params.data.s_Event == 0 ? zeroScoreStyle : params.data.s_Event < 1 ? oneScoreStyle : {}}>{params.value}</span>
        </Link>)
      case 'venue':
        return (
          <Tooltip arrow title={params.data.rename_Venue != '' ? `Renamed from "${params.data.venue}"` : params.data.venue} >
            <span style={!highlightCell ? undefined : params.data.s_Venue == 0 ? zeroScoreStyle : params.data.s_Venue < 1 ? oneScoreStyle : {}}>{params.value}</span>
          </Tooltip>
        )
      case 'smVenue':
        return <Tooltip arrow title={params.data.smVenue}><span style={!highlightCell ? undefined : params.data.s_Venue == 0 ? zeroScoreStyle : params.data.s_Venue < 1 ? oneScoreStyle : {}}>{params.value}</span></Tooltip>
      case 'city':
        return (
          <Tooltip arrow title={params.data.rename_City != '' ? `Renamed from "${params.data.city}"` : params.data.city} >
            <span style={!highlightCell ? undefined : params.data.s_City == 0 ? zeroScoreStyle : params.data.s_City < 1 ? oneScoreStyle : { color: params.data.rename_City != '' ? 'aqua' : '' }}>
              {params.data.rename_City || params.data.city}
            </span>
          </Tooltip>)
      case 'smCity':
        return (
          <Tooltip arrow title={params.data.smCity}>
            <span style={!highlightCell ? undefined : params.data.s_City == 0 ? zeroScoreStyle : params.data.s_City < 1 ? oneScoreStyle : {}}>
              {params.value}
            </span>
          </Tooltip>
        )
      case 'state':
        return (
          <Tooltip arrow title={params.data.rename_State != '' ? `Renamed from "${params.data.state}"` : params.data.state} >
            <span style={!highlightCell ? undefined : params.data.s_State == 0 ? zeroScoreStyle : params.data.s_State < 1 ? oneScoreStyle : {}}>
              {params.value}
            </span>
          </Tooltip>
        )
      case 'smState':
        return (
          <Tooltip arrow title={params.data.smState}>
            <span style={!highlightCell ? undefined : params.data.s_State == 0 ? zeroScoreStyle : params.data.s_State < 1 ? oneScoreStyle : {}}>
              {params.value}
            </span>
          </Tooltip>
        )
      default:
        return <span>{params.value}</span>
    }
  };


  const colDefs = useMemo(() => [
    { field: 'type', headerName: 'Type', checkboxSelection: true, headerCheckboxSelection: true },
    {
      headerName: 'Exact Matches Only',
      headerGroupComponent: ExactMatch,
      headerGroupComponentParams: { exactRef: eventRef },
      children: [
        {
          field: 'name', headerName: 'Event', minWidth: 242, wrapText: true, suppressSizeToFit: true,
          cellRenderer: ragRenderer
          , columnGroupShow: 'open'
        },
        {
          field: 'smEvent', headerName: 'SmEvent', minWidth: 242, wrapText: true, suppressSizeToFit: true,
          cellRenderer: ragRenderer,
          columnGroupShow: 'open'
        },
      ],
    },
    {
      headerName: 'Exact Matches Only',
      headerGroupComponent: ExactMatch,
      headerGroupComponentParams: { exactRef: venueRef },
      children: [
        {
          field: 'venue', headerName: 'Venue', minWidth: 160, wrapText: true, width: 160,
          cellRenderer: ragRenderer,
          columnGroupShow: 'open'

        },
        {
          field: 'smVenue', headerName: 'SmVenue', minWidth: 160, wrapText: true, autoHeight: true, width: 160,
          cellRenderer: ragRenderer,
          columnGroupShow: 'open'
        },
      ],
    },
    {
      headerName: 'Exact Matches Only',
      headerGroupComponent: ExactMatch,
      headerGroupComponentParams: { exactRef: cityRef },
      children: [
        {
          field: 'city', headerName: 'City', minWidth: 85,
          cellRenderer: ragRenderer,
          columnGroupShow: 'open'

        },
        {
          field: 'smCity', headerName: 'SmCity', minWidth: 85,
          cellRenderer: ragRenderer,
          columnGroupShow: 'open',
        }
      ],
    },
    {
      headerName: 'Exact Matches Only',
      headerGroupComponent: ExactMatch,
      headerGroupComponentParams: { exactRef: stateRef },
      children: [
        {
          field: 'state', headerName: 'State', width: 70, maxWidth: 72,
          cellRenderer: ragRenderer,
          columnGroupShow: 'open'
        },
        {
          field: 'smState', headerName: 'SmState', minWidth: 70, maxWidth: 88,
          cellRenderer: ragRenderer,
          columnGroupShow: 'open',
        },
      ],
    },
    {
      headerName: 'Exact Matches Only',
      headerGroupComponent: ExactMatch,
      headerGroupComponentParams: { exactRef: dateRef },
      children: [
        {
          field: 'dateTime', headerName: 'Date Time', minWidth: 110,
          valueParser: params => { return formatDateTime(params.value); },
          cellRenderer: ragRenderer,
        },
        {
          field: 'smDateTime', headerName: 'SmDate Time', minWidth: 110,
          valueParser: params => { return formatDateTime(params.value); },
          cellRenderer: ragRenderer,
        },
      ],
    },
    {
      headerName: 'Highlight',
      headerGroupComponent: CheckBoxHeader,
      headerGroupComponentParams: { SetHighLight: SetHighLight },
      children: [
        { field: 'score', headerName: 'Score', type: 'numericColumn', minWidth: 72, width: 72, maxWidth: 72 },
        { field: 'listingCount', headerName: 'Volume', type: 'numericColumn', minWidth: 100, width: 106, maxWidth: 106 },
        {
          field: 'tags', headerName: 'Tags',
          cellEditor: MultiSelectTags,
          cellEditorParams: { reloadPage: () => reloadPage('EventMatcher', refbtnFilter) },
          editable: true,
          cellRenderer: Tags,
          cellRendererParams: { reloadPage: () => reloadPage('EventMatcher', refbtnFilter) },
          cellEditorPopup: true,
          colId: "tags",
          cellEditorPopupPosition: 'over',
          tooltipValueGetter: (params) => 'Tagged by: ' + params.data.updatedBy
        }
      ],
    },
  ]);

  const sortingOrder = useMemo(() => {
    return ['desc', 'asc'];
  }, []);

  const [defaultValues, setDefaultValues] = useState({
    sortField: { field: 'ListingCount', order: 'DESC' },
  });

  const handleSearch = (filterList) => {
    if (eventRef.current.checked) filterList.S_Event_eq = 1;
    if (venueRef.current.checked) filterList.S_Venue_eq = 1;
    if (cityRef.current.checked) filterList.S_State_eq = 1;
    if (stateRef.current.checked) filterList.S_City_eq = 1;
    if (dateRef.current.checked) filterList.S_DateTime_eq = 1;
    gridRef.current.customFilterSearch(filterList);
  }

  const handleSetTags = () => {
    const tags = Array.from(tagSelected).join(',');
    const rowsSelect = gridRef.current.getAgGridInstance().api.getSelectedRows();

    if (rowsSelect.length === 0) {
      emptyEventMessage();
      return;
    } else if (tagSelected.length === 0) {
      emptyEventMessage('Please select tag');
      return;
    }
    if (confirm(`Are you sure to ${remove ? 'remove' : 'set'} ${tagSelected.length} selected Tags`)) {
      rowsSelect.forEach(x => {
        x.tags = tags;
        x.IsRemove = remove;
      });

      dataProvider.postData('EventMatcher', 'SetTagsSelected', rowsSelect)
        .then((response) => {
          if (response.status !== 200 && response.data) {
            notify('Set Tags successfully', { type: 'success', autoHideDuration: 2000 });
            reloadPage('EventMatcher', refbtnFilter)
          }
        })
        .catch(_ => {
          notify("Something went wrong", { type: 'error', autoHideDuration: 5000 });
        });
    }
  }

  const handleApprove = () => {
    const rowsSelect = gridRef.current.getAgGridInstance().api.getSelectedRows();
    if (rowsSelect.length === 0) {
      emptyEventMessage();
      return;
    }
    if (confirm(`Are you sure to approve ${rowsSelect.length} selected rows`)) {
      dataProvider.postData('EventMatcher', 'ApproveSelected', rowsSelect).then((response) => {
        if (response.status !== 200 && response.data) {
          notify('Updated Successfully', { type: 'success', autoHideDuration: 2000 });
          reloadPage('EventMatcher', refbtnFilter);
        }
      })
        .catch(_ => {
          notify("Something went wrong", { type: 'error', autoHideDuration: 5000 });
        });
    }
  }

  const onGridReady = ((params) => {
    var defaultSortModel = [
      { colId: 'listingCount', sort: 'desc', sortIndex: 0 },
    ];
    params.columnApi.applyColumnState({ state: defaultSortModel });
  });

  return (
    <div style={containerStyle}>
      <Title title={'Event Matcher'}></Title>
      <RenderControls
        actions={<>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={remove} onChange={() => setRemove(!remove)} />} label="Remove Tags" />
          </FormGroup>
          <SelectButton onClick={handleSetTags}>Tag selected</SelectButton>
          <ApproveButton onClick={handleApprove}>Approve</ApproveButton></>}
        controls={controls}
        defaultValues={defaultValueFilter}
        onSearch={handleSearch}
        refFilter={refbtnFilter}
        agSource={'EventMatcher'}
        agSort={defaultValues.sortField}
        exportFile={{
          filename: `EventMatcher-${formatLocalTime(new Date(), 'YY-MM-DD')}`,
          columns: ['Type', 'Name', 'SmEvent', 'Venue', 'SmVenue', 'City', 'SmCity', 'State', 'SmState',
            'DateTime', 'SmDateTime', 'Score', 'ListingCount', 'Tags', 'S_Event', 'S_Venue', 'S_State', 'S_City', 'S_DateTime'],
          limit: 1000000
        }}
      />

      <AgGrid
        rowSelection={'multiple'}
        ref={gridRef}
        suppressRowClickSelection={true}
        rowMultiSelectWithClick={true}
        list={'EventMatcher'}
        columnDefs={colDefs}
        sortingOrder={sortingOrder}
        defaultColDef={defaultColDef}
        defaultValues={defaultValues}
        setDefaultValues={setDefaultValues}
        onGridReady={onGridReady}
        // editType={'fullRow'}      
      ></AgGrid>
    </div>
  );
}


export default EventMatcherList;
