import * as React from 'react';
import MDialog from '../../../components/controls/MDialog';
import { Typography, Stack, Divider, Box, Button, LinearProgress, Link } from '@mui/material';
import { formatDateTime } from '../../../components/ex_dayjs';
import RenderControls from '../../../components/ra-list/RenderControls';
import { DataGrid } from '@mui/x-data-grid';
import { useNotify } from 'react-admin';
import { MTypography } from './MTypography';
import AutocompleteConfirm from './AutocompleteConfirm';

const buttonStyle = {
    background: '#5cb85c',
    color: 'white',
    borderColor: '#4cae4c',
    marginLeft: '10px',
    '&:hover': {
        background: '#449d44',
        borderColor: '#398439'
    }
};

const MappingPopup = ({ data, dataProvider, handleClose, reloadPage, smMarket }) => {
    const { m_isOpen, m_item, m_events, m_isLoading } = data;
    const notify = useNotify();
    const [selectionModel, setSelectionModel] = React.useState([]);
    const [sortModel, setSortModel] = React.useState([{ field: 's_total_score', sort: 'desc' }]);
    const [rows, setRows] = React.useState([]);
    const [state, setState] = React.useState({
        title: '',
        mappingBtn: 'Mapping!',
        skipBtn: 'skip pricer',
        mapTo: {},
        isProcessing: false, // process call api to apply
        isSelection: false,
        isSkipPricer: false,
        c_isConfirm: false // OPen dialog confirm
    });

    React.useEffect(() => {
        setSelectionModel([]);
        if (m_item) {
            var isMapped = m_item.mapped == 1;
            var title = `#${m_item.id}`;
            if (m_item.smEventID) title += ` | Portal EventID: ${m_item.smEventID.slice(0, m_item.smEventID.length - 2)}`;
            setState((prev) => ({
                ...prev, title,
                isSelection: isMapped,
                mappingBtn: isMapped ? 'Unmap!' : 'Mapping!',
                mapTo: {
                    url: m_item.smUrl,
                    name: m_item.smEvent,
                    venue: m_item.smVenue,
                    date: m_item.smDateTime,
                    city: m_item.smCity,
                    state: m_item.smState
                }
            }));
        }
    }, [m_item]);

    React.useEffect(() => {
        setRows(m_events);
        // Focus first row
        if (m_events && m_events.length) {
            let idSelected = m_events.find(x => x.sm_market_id == smMarket)?.sm_original_id;
            const intervalId = setInterval(() => {
                let firstRow = null;
                if (idSelected) firstRow = document.querySelector(`.MuiDataGrid-root [data-id="${idSelected}"]`);
                if (!firstRow) firstRow = document.querySelectorAll('.MuiDataGrid-root [data-id]')?.[0];
                if (firstRow) {
                    firstRow.click();
                    clearInterval(intervalId);
                }
            }, 100);
        }
    }, [m_events]);

    const columnDefs = [
        { field: 'sm_market', headerName: 'Exchange', width: 100 },
        { field: 'sm_original_id', headerName: 'SM EventID', width: 100 },
        {
            field: 'sm_event_name', headerName: 'Event', width: 350, renderCell: (record) => (
                <Link underline="hover" href={record.row.sm_event_url} target="_blank">
                    {record.value}
                </Link>
            )
        },
        { field: 'sm_event_venue', headerName: 'Venue', width: 300 },
        { field: 'sm_event_city', headerName: 'City', width: 200 },
        { field: 'sm_event_state', headerName: 'State', width: 100 },
        { field: 'sm_event_date', headerName: 'Event date', width: 200, valueFormatter: ({ value }) => formatDateTime(value) },
        { field: 's_total_score', headerName: 'Score', width: 100 },
    ];

    const handleSortModelChange = (newModel) => {
        setSortModel(newModel);
    };

    const handleSelectionModelChange = (newSelection) => {
        setSelectionModel(newSelection);
        // selectMapto
        if (m_item.mapped != 1) {
            let dataModel = m_events.find(x => newSelection.some(y => y == x.sm_original_id));
            if (dataModel) {
                setState((prev) => ({
                    ...prev, mapTo: {
                        url: dataModel.sm_event_url,
                        name: dataModel.sm_event_name,
                        venue: dataModel.sm_event_venue,
                        date: dataModel.sm_event_date,
                        city: dataModel.sm_event_city,
                        state: dataModel.sm_event_state,
                    }
                }));
            }
        }
        // Mapped then allow skip/enable pricer
        else if (m_item.mapped == 1) {
            let dataModel = m_events.filter(x => newSelection.some(y => y == x.sm_original_id));
            if (dataModel.length) {
                let isEnable = dataModel.every(x => x.skip_auto_pricer === true);
                setState((prev) => ({ ...prev, skipBtn: isEnable ? 'enable pricer' : 'skip pricer' }));
            }
        }
    };

    const closeConfirm = () => setState((prev) => ({ ...prev, c_isConfirm: false }));
    const closeProcessing = () => setState((prev) => ({ ...prev, isProcessing: false }));

    const controls = [
        { component: 'number', name: 'sm_original_id', label: 'SM Event ID', xs: 2 },
        { component: 'text', name: 'sm_event_name', label: 'Event', xs: 5 },
        { component: 'text', name: 'sm_event_venue', label: 'Venue', xs: 5 }
    ];

    function handleSearch(data) {
        let isChanged = controls.some((m) => data[m.name]);
        var newData = m_events.filter(x => {
            for (let control of controls) {
                let isValid = true;
                if (data[control.name]) {
                    if (control.component == 'text')
                        isValid = x[control.name].toLowerCase().includes(data[control.name].toLowerCase());
                    else
                        isValid = x[control.name] == data[control.name]
                }
                if (!isValid) return false;
            }
            return true;
        });
        if (isChanged) setRows(newData);
        else setRows(m_events);
    }

    function handleMapping(reason) {
        var isMapped = m_item.mapped == 1;
        var dataRequest = [];
        dataProvider.currentUser().then((user) => {
            if (user.email) {
                if (isMapped) { // For unMapping
                    var dataModel = m_events.filter(x => selectionModel.some(y => y == x.sm_original_id));
                    dataModel.forEach((e) => {
                        if (e.mapped) {
                            let d = {};
                            d.EventID = m_item.id;
                            d.sm_event_id = e.sm_event_id;
                            d.sm_event_date = new Date(e.sm_event_date);
                            d.sm_market_id = e.sm_market_id;
                            d.ReasonTags = reason;
                            d.Mapped = isMapped;
                            e.Mapped_By = user.email;
                            d.json = getDataMap(e);
                            dataRequest.push(d);
                        }
                    });
                } else { // For Mapping
                    var dataMap = m_events.find(x => selectionModel.some(y => y == x.sm_original_id));
                    dataMap.EventID = m_item.id;
                    dataMap.sm_event_date = new Date(dataMap.sm_event_date);
                    dataMap.Mapped = isMapped;
                    dataMap.Mapped_By = user.email;
                    if (dataMap.mapped) dataMap.sm_event_id = m_item.smEventID;
                    dataMap.json = getDataMap(dataMap);
                    dataRequest.push(dataMap);
                }

                if (dataRequest.length) {
                    setState((prev) => ({ ...prev, isProcessing: true }));
                    dataProvider.postData('ListingsMapping', `MappingEvent`, dataRequest)
                        .then((response) => {
                            closeProcessing();
                            if (response && response.data) {
                                if (response.data === true) {
                                    notify(`The event '${m_item.name}' is ${m_item.mapped == 1 ? "Unmapped" : "Mapped"}!!! `, { type: 'success' });
                                    handleClose(null, 'm_isOpen');
                                    reloadPage();
                                } else notify(`${response.data}`, { type: 'error' });
                            }
                        });
                } else {
                    notify(`Please select the mapped events to unmap!!!`, { type: 'warning' });
                }
            }
        });

        function getDataMap(dmap) {
            return JSON.stringify({
                Name: m_item.name,
                DateTime: m_item.dateTime,
                Venue: m_item.venue,
                City: m_item.city,
                State: m_item.state,
                SmName: dmap.sm_event_name,
                SmDateTime: dmap.sm_event_date,
                SmVenue: dmap.sm_event_venue,
                SmCity: dmap.sm_event_city,
                SmState: dmap.sm_event_state,
                Score: dmap.s_total_score,
                MarketId: dmap.sm_market_id,
                Mapped_By: dmap.Mapped_By,
                Mapped_Time: new Date(),
                S_Event: dmap.s_Event,
                S_Venue: dmap.s_Venue,
                S_City: dmap.s_City,
                S_State: dmap.s_State,
                S_DateTime: dmap.s_DateTime
            });
        }
    }

    function handleSkipPricer() {
        var isMapped = m_item.mapped == 1;
        var isSkip = state.skipBtn == 'skip pricer';
        var dataRequest = [];
        if (isMapped) { // For skip pricer
            var dataModel = m_events.filter(x => x.mapped && selectionModel.some(y => y == x.sm_original_id));
            dataModel.forEach((e) => {
                let d = {};
                d.EventID = m_item.id;
                d.sm_event_id = e.sm_event_id;
                d.sm_event_date = new Date(e.sm_event_date);
                d.sm_market_id = e.sm_market_id;
                d.Mapped = isMapped;
                dataRequest.push(d);
            });
        }
        if (dataRequest.length) {
            setState((prev) => ({ ...prev, isProcessing: true }));
            dataProvider.postData('ListingsMapping', `SkipPricers?isSkip=${isSkip}`, dataRequest)
                .then((response) => {
                    closeProcessing();
                    if (response && response.data) {
                        if (response.data >= 0) {
                            notify(`The event '${m_item.name}' has been ${isSkip ? 'skipped' : 'enabled'} (${response.data})!!! `, { type: 'success' });
                            handleClose(null, 'm_isOpen');
                            reloadPage();
                        } else notify(`${response.data}`, { type: 'error' });
                    }
                });
        } else {
            notify(`Please select the mapped events to skip!!!`, { type: 'warning' });
        }
    }

    return (
        <>
            <MDialog name='m_isOpen'
                open={m_isOpen}
                title={state.title}
                handleClose={handleClose}
                msgLoading={`${state.isSkipPricer ? (state.skipBtn == 'skip pricer' ? 'Skipping' : 'Enabling') : m_item.mapped == 1 ? 'Unmapping' : 'Mapping'}`}
                disabled={state.isProcessing}
                maxWidth="xl"
            >
                <Typography variant="h4">You're mapping</Typography>
                <Divider orientation="horizontal" flexItem style={{ paddingTop: 5 }} />
                <Stack
                    direction="row"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={1}
                    style={{ paddingTop: 10 }}
                >
                    <MTypography label='Event' name={m_item.name} href={m_item.url} />
                    <MTypography label='Venue' name={m_item.venue} />
                    <MTypography label='Date' datetime={m_item.dateTime} />
                    <MTypography label='City' name={m_item.city} />
                    <MTypography label='State' name={m_item.state} />
                </Stack>
                <Stack
                    direction="row"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={1}
                    style={{ paddingTop: 10 }}
                >
                    <MTypography label='Map to' name={state.mapTo.name} href={state.mapTo.url} />
                    <MTypography label='Venue' name={state.mapTo.venue} />
                    <MTypography label='Date' datetime={state.mapTo.date} />
                    <MTypography label='City' name={state.mapTo.city} />
                    <MTypography label='State' name={state.mapTo.state} />
                </Stack>
                <RenderControls
                    filterInline={false}
                    onSearch={handleSearch}
                    positionActions='start'
                    sizeFilter={'normal'}
                    actionsStyle={{ paddingTop: 5, paddingBottom: 5 }}
                    actions={
                        <>
                            <Button size='normal'
                                sx={buttonStyle}
                                onClick={() => {
                                    if (selectionModel.length) setState((prev) => ({ ...prev, c_isConfirm: true, isSkipPricer: false }));
                                    else notify(`Please select event to ${m_item.mapped == 1 ? 'unmap' : 'mapping'}!!!`, { type: 'warning' });
                                }}>
                                {state.mappingBtn}
                            </Button>
                            {m_item.mapped == 1 && (
                                <Button size='normal'
                                    sx={buttonStyle}
                                    onClick={() => {
                                        if (selectionModel.length) setState((prev) => ({ ...prev, c_isConfirm: true, isSkipPricer: true }));
                                        else notify(`Please select event to ${state.skipBtn}!!!`, { type: 'warning' });
                                    }}>
                                    {state.skipBtn}
                                </Button>
                            )}
                        </>
                    }
                    controls={controls} />
                {m_isLoading && <LinearProgress color='secondary' />}
                <Box sx={{ height: 400, width: '100%' }}>
                    <DataGrid
                        rows={rows}
                        columns={columnDefs}
                        getRowId={(r) => r.sm_original_id}
                        sortModel={sortModel}
                        autoPageSize={true}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 25,
                                },
                            },
                        }}
                        rowSelectionModel={selectionModel}
                        checkboxSelection={state.isSelection}
                        onRowSelectionModelChange={handleSelectionModelChange}
                        onSortModelChange={handleSortModelChange}
                    />
                </Box>
            </MDialog>
            <AutocompleteConfirm
                isOpen={state.c_isConfirm}
                title={`Are you sure apply(${state.isSkipPricer ? state.skipBtn : m_item.mapped == 1 ? 'unmap' : 'mapping'}) for this event(s)?`}
                showReason={m_item.mapped == 1 && !state.isSkipPricer}
                onClose={closeConfirm}
                onConfirm={(reason) => {
                    setState((prev) => ({ ...prev, c_isConfirm: false }));
                    if (state.isSkipPricer) handleSkipPricer();
                    else handleMapping(reason);
                }}
            />
        </>
    );
};

export default MappingPopup;