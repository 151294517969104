const REASON_TAGS = [
    {
        id: 'Limited Availability',
        name: 'Event or Section TSO, High PO rate event'
    },
    {
        id: 'Unable to Process',
        name: 'Too many failed attempts'
    },
    {
        id: 'Listing Issue',
        name: 'Mapping issue, PPE, Postponed/TBA, Unclear Delivery'
    }
];

export {
    REASON_TAGS
}